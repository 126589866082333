import { RouterProvider } from 'react-router-dom';

import {
  QueryClient,
  QueryCache,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { router } from './routes';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { clarity } from 'react-microsoft-clarity';
import { useAuthContext } from './hooks/useAuthContext';
import { catchMutationErrorForSentry } from './utils/catchMutationErrorForSentry';
import { setUser } from '@sentry/react';
import useBroadcastChannel from './hooks/useBroadcastChannel';
import { useUserCompaniesStore } from './stores/useUserCompaniesStore';
import { BROADCAST_CHANNELS } from './constants/broadcastChannels';
import { Company } from './types/company';

function App() {
  const toast = useToast();

  const { user } = useAuthContext();
  const { selectedCompany } = useUserCompaniesStore();

  setUser({
    id: user?.id,
    email: user?.username,
    username: user?.username,
    customerId: user?.selectedCompany?.customerId,
    companyName: user?.selectedCompany?.name,
    companyTradeName: user?.selectedCompany?.tradeName,
  });

  if (clarity.hasStarted()) {
    clarity.identify(user?.username || '', {
      id: user?.id,
      customerId: user?.selectedCompany?.customerId,
      email: user?.username,
      userHint: user?.selectedCompany?.name,
    });
    clarity.setTag('userId', user?.id || '');
    clarity.setTag('username', user?.username || '');
    clarity.setTag('customerId', user?.selectedCompany?.customerId || '');
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
              if (isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (
                  axiosError.response?.status === 404 ||
                  axiosError.response?.status === 498
                )
                  return false;
              }
              return failureCount < 2;
            },
          },
        },
        queryCache: new QueryCache({
          onError: (_error, query) => {
            const errorMessage = query.meta?.errorMessage;
            if (errorMessage && typeof errorMessage === 'string') {
              toast({
                title: 'Tivemos um problema para carregar os dados.',
                status: 'error',
                description: errorMessage,
              });
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            const mutationIdentifier = mutation.options.mutationFn?.toString();
            catchMutationErrorForSentry(error, mutationIdentifier);
          },
        }),
      })
  );

  useBroadcastChannel({
    name: BROADCAST_CHANNELS.companySwitch,
    onMessage: (event: MessageEvent<{ company: Company }>) => {
      if (selectedCompany?.id !== event.data.company?.id) {
        router.navigate('/');
        window.location.reload();
      }
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
