import { Employee } from '../../../types/employee';
import { PrepareRechargeData } from '../../../types/recharge';

type AggregateCreditDateReturn = PrepareRechargeData['creditDates'][0];

export function aggregateRechargeDates(
  employees: Employee[]
): AggregateCreditDateReturn[] {
  const datesMap = new Map<string, AggregateCreditDateReturn>();

  employees.forEach((employee) => {
    if (!employee?.creditDate) return;

    const creditDate = datesMap.get(employee.creditDate) || {
      date: employee.creditDate,
      totalValue: 0,
      numberOfCollaborators: 0,
    };

    const totalValue = employee.categories.reduce(
      (sum, categ) => sum + categ.value,
      creditDate.totalValue
    );

    datesMap.set(employee.creditDate, {
      date: employee.creditDate,
      totalValue,
      numberOfCollaborators: creditDate.numberOfCollaborators + 1,
    });
  });

  return Array.from(datesMap.values());
}
