import { IDeveloperField } from 'dromo-uploader-react';
import { EmployeeBenefitCategory } from '../../../types/employee';

function getBenefitCategoryFields(
  benefitCategories: EmployeeBenefitCategory[]
): IDeveloperField[] {
  const fields: IDeveloperField[] = benefitCategories.map((category) => {
    return {
      key: category.name,
      type: 'string',
      label: category.name,
      requireMapping: true,
      alternateMatches: [
        `Valor total em R$ para recarregar em ${category.name} (digite só números)`,
      ],
      validators: [],
      description: `Valor total em R$ para recarregar em ${category.name}`,
    };
  });

  return fields;
}

function getCreditDateField(
  paysRechargeWithMultipleCreditDates: boolean
): IDeveloperField[] {
  if (paysRechargeWithMultipleCreditDates) {
    return [
      {
        key: 'creditDate',
        type: [
          'date',
          {
            displayFormat: 'dd/MM/yyyy',
            outputFormat: 'dd/MM/yyyy',
            locale: 'pt-BR',
          },
        ],
        label: 'Data do crédito',
        description:
          'Informe a data para o crédito ao colaborador (exemplo, 10/10/2024). Caso a data seja a mesma para todos os colaboradores desta planilha, deixe este campo em branco e defina a data diretamente no Portal Raiô após a importação',
        requireMapping: true,
        validators: [
          {
            validate: 'required',
            errorMessage:
              'Você precisa preencher a Data de Crédito para todos os colaboradores',
          },
        ],
        invalidValueMessage:
          'Por favor, digite a data no formato DD/MM/AAAA (por exemplo, 22/01/2024)',
        alternateMatches: [
          'Data do crédito na conta do colaborador (dd/mm/aaaa)',
        ],
      },
    ];
  } else {
    return [];
  }
}

export function getDromoFields(
  benefitCategories: EmployeeBenefitCategory[],
  paysRechargeWithMultipleCreditDates: boolean
): IDeveloperField[] {
  const benefitCategoryFields = getBenefitCategoryFields(benefitCategories);

  const creditDateField = getCreditDateField(
    paysRechargeWithMultipleCreditDates
  );

  const uniqueCpfMessage = `Este CPF aparece em duas ou mais linhas. Se houver valores preenchidos na mesma categoria em linhas distintas${paysRechargeWithMultipleCreditDates ? ' e as datas de crédito forem iguais' : ''}, estes serão somados`;

  const defaultFields: IDeveloperField[] = [
    {
      key: 'document',
      type: 'string',
      label: 'CPF do colaborador',
      requireMapping: true,
      alternateMatches: [
        'CPF (obrigatório)',
        'CPF do colaborador (obrigatório)',
      ],
      validators: [
        {
          validate: 'required',
          errorMessage: 'Você precisa preencher o CPF',
        },
        {
          validate: 'unique_case_insensitive',
          errorMessage: uniqueCpfMessage,
          level: 'warning',
        },
        {
          validate: 'regex_match',
          // regex to only allow numbers and 11 digits
          regex: '^[0-9]{11}$',
          errorMessage:
            'Por favor, digite somente os 11 números do CPF do colaborador, sem caracteres especiais',
        },
      ],
    },
    ...creditDateField,
  ];

  return defaultFields.concat(benefitCategoryFields);
}
