import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from '@chakra-ui/react';

const base2: ThemeOverride = {
  styles: {
    global: {
      'html, body ': {
        minHeight: '100vh',
        bg: '#f3f3f3',
        color: '#060606',
        fontVariantLigatures: 'none',
      },

      '*::-webkit-scrollbar': { height: '8px', width: '8px' },
      '*::-webkit-scrollbar-track': {
        borderRadius: '4px',
        backgroundColor: '#E8F2F4',
      },
      '*::-webkit-scrollbar-track:hover': { backgroundColor: '#E8F2F4' },
      '*::-webkit-scrollbar-track:active': { backgroundColor: '#E8F2F4' },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: '#959592',
      },
      '*::-webkit-scrollbar-thumb:hover': { backgroundColor: '#838381' },
      '*::-webkit-scrollbar-thumb:active': { backgroundColor: '#767674' },
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
    heading: `'Sora', sans-serif`,
  },
  colors: {
    white: '#FEFEFC',
    black: '#060606',

    gray: {
      black: '#060606',
      base_white: '#FEFEFC',
      25: 'rgba(195, 195, 195, 0.20)',
      50: '#FBFBFB',
      100: '#F3F3F3',
      200: '#F8F7F3',
      300: '#CDCCCE',
      400: '#E8E5D9',
      500: '#D6D1C0',
      600: '#B0A991',
      700: '#76764',
      800: '#333333',
      900: '#767674',
    },

    orange: {
      50: '#FBF2E3',
      100: '#ffd0ae',
      200: '#ffb27e',
      300: '#ff944c',
      400: '#ff751a',
      500: '#FF6600',
      600: '#99440A',
      700: '#7A4A29',
      800: '#4f1d00',
      900: '#200700',
    },

    primary: {
      primary: '#FF6600',
      100: '#FF7B00',
      200: '#99440A',
      300: '#7A4A29',
      400: '#FBF2E3',
    },
    secondary: {
      secondary: '#F4AD00',
      100: '#F4AD00',
      200: '#FFFFCC',
      300: '#FFCC99',
    },
    benefits: {
      border: {
        foodAndMeal: '#982400',
        food: '#C9320D',
        meal: '#F95F7B',
        culture: '#DF6A00',
        mobility: '#AC41A1',
        fuel: '#603FBF',
        health: '#5B99EF',
        education: '#22A968',
        allInOne: '#FFC700',
        awards: '#7CAF96',
        remoteWork: '#26335D',
        incentive: '#609AAC',
        freeBalance: '#2DB393',
        christmas: '#CA5C39',
        birthday: '#D860D4',
        attendance: '#6978FF',
      },
      background: {
        foodAndMeal: '#F4E8E3',
        food: '#F9EAE4',
        meal: '#FCECED',
        culture: '#FBEFE3',
        mobility: '#F6EBF3',
        fuel: '#EEEBF6',
        health: '#EEF4F9',
        education: '#E8F5ED',
        allInOne: '#FEFBED',
        awards: '#F1F6F2',
        remoteWork: '#E8EAEC',
        incentive: '#EEF4F4',
        freeBalance: '#E9F6F1',
        christmas: '#F9EEE8',
        birthday: '#FAEEF8',
        attendance: '#EFF1FC',
      },
    },
    feedback: {
      success: '#1e7500',
      alert: '#db0200',
      warning: '#CC5200',
    },
    status: {
      active: '#1E7500',
      activeBg: '#DEE6DB',
      inactive: '#DB0200',
      inactiveBg: '#F1DBDB',
      paused: '#3D4294',
      pausedBg: '#E9E5EC',
      waiting: '#FF7B00',
      waitingBg: '#FBF2E3',
      loading: '#BF3F75',
      loadingBg: '#F2E5E9',
    },
    light: {
      green: 'rgba(30, 117, 0, 0.10)',
      orange: 'rgba(222, 135, 6, 0.10)',
      blue: 'rgba(61, 66, 148, 0.10)',
      gray: 'rgba(53, 53, 53, 0.10)',
      red: 'rgba(219, 2, 0, 0.10)',
      pink: 'rgba(191, 63, 117, 0.10)',
    },
  },

  shadows: {
    outline: '0 0 0 3px #ae6a02',
  },

  components: {
    Card: {
      baseStyle: {
        container: {
          position: 'unset',
          background: 'gray.base_white',
          borderRadius: 'xl',
        },
        header: {
          padding: '24px',
        },
        body: {
          padding: '24px',
        },
        footer: {
          padding: '24px',
        },
      },
    },
    Stepper: {
      baseStyle: {
        backgroundColor: 'gray.300',
        indicator: {
          borderColor: 'gray.300',
        },

        separator: {
          backgroundColor: 'gray.300',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.900',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.black',
            },
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: 'gray.300',
            backgroundColor: 'gray.base_white',
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.primary',
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.black',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.black',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.primary',
        border: '1px solid',
        borderColor: 'gray.base_white',
      },
    },

    Select: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.700',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.black',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.primary',
      },
    },

    Spinner: {
      baseStyle: {
        color: 'primary.primary',
      },
    },

    Button: {
      baseStyle: {
        fontWeight: 700,
      },
      defaultProps: {
        focusBorderColor: 'primary.primary',
        background: 'primary.primary',
      },
      sizes: {
        md: {
          h: '56px',
          px: '32px',
          borderRadius: '28px',
          width: '345px',
        },
      },
      variants: {
        solid: {
          background: 'primary.100',
          color: 'gray.base_white',
          _hover: {
            background: 'primary.200',
          },
          _active: {
            background: 'primary.300',
          },
        },

        link: {
          color: 'primary.200',
          width: 'fit-content',
          padding: '4px 8px',
          textDecoration: 'underline',
          fontWeight: 400,
          fontSize: 'md',
          _hover: {
            color: 'primary.primary',
            _disabled: {
              textDecoration: 'underline',
            },
          },
          _active: {
            color: 'primary.100',
          },
          _disabled: {
            _active: {
              color: 'inherit',
            },
          },
        },
        ghost: {
          width: 'fit-content',
          height: 'fit-content',
          padding: '8px 16px',
          borderRadius: 'md',
          fontWeight: 400,
          color: 'gray.800',
          _hover: {
            background: 'none',
          },
        },
        outline: {
          color: 'primary.primary',
          _hover: {
            background: 'primary.100',
            color: 'white',
            _disabled: {
              background: 'transparent',
              color: 'primary.100',
            },
          },
          _active: {
            background: 'primary.primary',
            color: 'white',
          },
        },

        pagination: {
          boxSize: '40px',
          backgroundColor: 'gray.50',
          color: 'gray.800',
          borderRadius: 'lg',
          svg: {
            width: '24px',
            height: '24px',
          },
          _hover: {
            backgroundColor: 'gray.300',
            opacity: 1,
            _disabled: {
              backgroundColor: 'gray.300',
            },
          },
          _disabled: {
            opacity: 1,
            backgroundColor: 'gray.50',
            cursor: 'pointer',
          },
        },
      },
    },

    FormLabel: {
      baseStyle: {
        fontWeight: 400,
        marginBottom: 1,
      },
    },

    Radio: {
      baseStyle: {
        control: {
          borderColor: 'gray.300',
          _checked: {
            background: 'primary.primary',
            borderColor: 'primary.primary',
          },
        },
      },
      defaultProps: {
        colorScheme: 'orange',
      },
      sizes: {
        xl: {
          control: {
            width: '24px',
            height: '24px',
          },
        },
        sm: {
          control: {
            width: '20px',
            height: '20px',
          },
          label: {
            fontSize: '14px',
          },
        },
      },
    },

    Tooltip: {
      baseStyle: {
        bg: 'gray.black',
        color: { base: 'white', sm: 'gray.50' },
        lineHeight: { base: '1.3', sm: 'unset' },
        padding: { base: '16px', sm: 'lg' },
        fontSize: { base: '16px', sm: 'md' },
        borderRadius: 'sm',
        fontWeight: 600,
        zIndex: '9999',
        minWidth: { base: '100vw', sm: 'unset' },
        position: { base: 'bottom', sm: 'unset' },
      },
    },

    Checkbox: {
      baseStyle: {
        control: {
          borderColor: 'gray.300',
          _checked: {
            background: 'primary.primary',
            borderColor: 'primary.primary',
          },
          _indeterminate: {
            background: 'primary.primary',
            borderColor: 'primary.primary',
          },
        },
      },
      sizes: {
        md: {
          label: {
            fontSize: { base: '12px', sm: '16px' },
          },
        },
      },
      defaultProps: {
        colorScheme: 'orange',
      },
    },

    Divider: {
      baseStyle: {
        borderColor: 'gray.300',
      },
    },

    Modal: {
      baseStyle: {
        overlay: {
          background: 'blackAlpha.600',
          backdropFilter: 'blur(4px)',
        },
        dialog: {
          padding: 4,
        },
        closeButton: {
          top: 8,
          right: 8,
        },
      },
    },

    Drawer: {
      variants: {
        clickThrough: {
          dialogContainer: {
            pointerEvents: 'none',
          },
          dialog: {
            pointerEvents: 'auto',
            boxShadow:
              'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
          },
        },
      },
    },

    Tabs: {
      baseStyle: {
        root: {
          borderColor: 'gray.300',
          borderBottomColor: 'gray.300',
        },
        tab: {
          _active: {
            bg: 'gray.100',
          },
          _selected: {
            color: 'primary.primary',
            borderColor: 'primary.primary',
            borderBottomColor: 'primary.primary',
            fontWeight: '600',
            borderBottom: '2px solid',
          },
        },
        tablist: {},
      },
    },

    Link: {
      baseStyle: {
        textDecoration: 'underline',
        color: 'primary.200',
        _hover: {
          color: 'primary.primary',
        },
      },
    },

    Table: {
      defaultProps: {
        colorScheme: 'gray',
      },
      baseStyle: {
        table: {
          backgroundColor: 'gray.base_white',
          padding: '24px',
          borderRadius: 'md',
          fontVariantNumeric: 'normal',
        },
        thead: {
          th: {
            color: 'inherit',
            fontSize: 'md',
            fontWeight: 600,
            paddingX: 8,
            paddingBottom: 8,
            paddingTop: 4,
            textTransform: 'unset',
            borderBottom: '1px solid',
            borderColor: 'gray.300',
            fontFamily: 'body',
            letterSpacing: 'unset',
          },
        },
        tbody: {
          tr: {
            td: {
              paddingY: 4,
              paddingX: 8,
            },
          },
        },
      },
      variants: {
        raw: {
          table: {},
          thead: {
            th: {
              paddingX: 6,
              paddingY: 4,
              borderColor: 'gray.700',
            },
          },
          tbody: {
            tr: {
              borderColor: 'gray.700',
              td: {
                paddingY: 4,
                paddingX: 6,
                borderColor: 'gray.700',
              },
            },
          },
        },
      },
    },

    Popover: {
      baseStyle: {
        closeButton: {
          top: 2,
          right: 2,
        },
      },
    },

    Menu: {
      baseStyle: {
        list: {
          boxShadow: 'md',
          zIndex: '9999',
        },
      },
    },
    Badge: {
      variants: {
        green: {
          padding: '4px 8px',
          borderRadius: '16px',
          color: 'status.active',
          background: 'status.activeBg',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        blue: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'status.pausedBg',
          color: 'status.paused',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        orange: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'primary.400',
          color: 'primary.100',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        pink: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'status.loadingBg',
          color: 'status.loading',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        gray: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'light.gray',
          color: 'gray.800',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
      },
    },

    Skeleton: {
      baseStyle: {
        borderRadius: 'xl',
      },
    },
  },
  breakpoints: {
    '1xl': '1342px',
  },
};

export const theme2 = extendTheme(
  withDefaultColorScheme({ colorScheme: 'orange' }),
  base2
);
