import { Button, Card, CardProps, Flex, Text } from '@chakra-ui/react';
import { BoxIcon } from '../../../../assets/customIcons/BoxIcon';
import { LuPlusCircle } from 'react-icons/lu';

type BenefitEmptyStateProps = CardProps & {
  onOpen: () => void;
  isOpen: boolean;
  loadingCompanyBenefitCategories: boolean;
};

const BenefitEmptyState = ({
  onOpen,
  isOpen,
  loadingCompanyBenefitCategories,
  ...props
}: BenefitEmptyStateProps) => {
  return (
    <Card
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      minHeight="250px"
      padding={8}
      {...props}
    >
      <BoxIcon boxSize="56px" color="primary.primary" />
      <Flex alignItems="center" flexDirection="column">
        <Text
          fontSize="32px"
          fontWeight={700}
          color="gray.black"
          fontFamily="Sora"
          textColor={'colors.black'}
        >
          Já Raiô!
        </Text>
        <Text
          fontSize="32px"
          fontWeight={400}
          fontFamily="Inter"
          textColor={'colors.black'}
        >
          Você ainda não possui nenhum pacote cadastrado
        </Text>
      </Flex>

      <Flex alignItems="center" justifyContent="center" width="100%">
        <Button
          width="fit-content"
          leftIcon={<LuPlusCircle size="20px" />}
          onClick={onOpen}
          isLoading={isOpen && loadingCompanyBenefitCategories}
        >
          Criar um novo pacote
        </Button>
      </Flex>
    </Card>
  );
};

export default BenefitEmptyState;
