import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';

import { FiCheck } from 'react-icons/fi';
import PasswordInput from '../../components/PasswordInput';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import PortalTermsOfUseLink from '../../components/PortalTermsOfUseLink';
import { AuthState } from '../../types/auth';
import Spinner from '../../components/Spinner';
import { AxiosError, AxiosResponse } from 'axios';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';
import FullScreenBgFirstAccessLayout from '../../layouts/FullScreenBackgroundFirstAccessLayout';

type TokenValidationQueryResponse = {
  passwordAlreadySet: boolean;
};

const PasswordSettingPage = () => {
  const { allRulesChecked, handlePasswordChange, password, passwordRules } =
    usePasswordValidation();

  const { confirmationToken } = useParams();

  const { setAuthInfo, isAuthenticated } = useAuthContext();
  const { api } = useFetchContext();

  const toast = useToast();
  const navigate = useNavigate();

  const { setSelectedCompany } = useUserCompaniesStore();

  const tokenValidationQuery = useQuery<
    AxiosResponse<TokenValidationQueryResponse>,
    AxiosError,
    TokenValidationQueryResponse
  >({
    queryKey: ['validate-first-access-token', confirmationToken],
    queryFn: () =>
      api.get<TokenValidationQueryResponse>(
        `/api/auth/validate-first-access-token/${confirmationToken}`
      ),
    select: (response) => response.data,
  });

  const updatePassword = useMutation({
    mutationFn: () =>
      api.post<AuthState>(`/api/users/password/${confirmationToken}`, {
        password,
      }),
    onSuccess: (response) => {
      const authInfo = response.data;
      if (authInfo.accessToken && authInfo.user) {
        setSelectedCompany(authInfo.user?.selectedCompany);
        setAuthInfo(authInfo);
        toast({
          title: 'Senha definida com sucesso!',
          status: 'success',
        });
        navigate('/');
      }
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro ao definir sua senha.',
        description: 'Por favor, tente novamente.',
        status: 'error',
      });
    },
  });

  if (tokenValidationQuery.isFetching) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (isAuthenticated() && confirmationToken) {
    return <Navigate to={`/switch/${confirmationToken}`} />;
  }

  // in the future we'll present a different message for this case
  if (
    tokenValidationQuery.isError &&
    tokenValidationQuery.error.response?.status === 498
  ) {
    return <Navigate to={`/login`} />;
  }

  if (tokenValidationQuery.data?.passwordAlreadySet) {
    return <Navigate to={`/login`} />;
  }

  return (
    <FullScreenBgFirstAccessLayout>
      <Box
        paddingX={{
          base: 'unset',
          md: '80px',
        }}
      >
        <Box marginBottom="32px" marginLeft={{ base: 6, sm: 'unset' }}>
          <Heading fontSize={{ base: '24px', lg: '32px' }} marginBottom="8px">
            Boas-vindas!
          </Heading>
          <Text
            fontSize={{ base: '24px', md: '32px' }}
            lineHeight="130%"
            fontStyle="normal"
            fontFamily="inter"
            fontWeight="400"
          >
            Este é o seu{' '}
            <Text as="span" fontWeight="700">
              {' '}
              portal Raiô.{' '}
            </Text>
          </Text>
        </Box>

        <Card
          backgroundColor="gray.50"
          width="100%"
          boxShadow="none"
          position="relative"
          minWidth="327px"
        >
          <HourglassIcon
            position="absolute"
            top={{ base: '-20px', sm: '-27px' }}
            right={{ base: '20px', sm: '-30px' }}
            width={{ base: '40.438px', sm: '55.8px' }}
            height={{ base: '40.012px', sm: '54.51px' }}
          />

          <CardHeader>
            <Flex direction="column" gap={4}>
              <Heading fontSize={{ base: '18px', sm: '24px' }}>
                Crie uma senha de acesso
              </Heading>
            </Flex>
          </CardHeader>

          <CardBody paddingTop={0}>
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              marginBottom={{ base: 1, sm: 'unset' }}
            >
              Sua senha precisa ter, pelo menos:
            </Text>
            <UnorderedList>
              <Box marginLeft={2} gap={1}>
                {passwordRules.map((rule) => (
                  <ListItem
                    key={rule.description}
                    color={rule.check ? 'feedback.success' : 'inherit'}
                    alignItems="center"
                    gap={1}
                    fontSize={{ base: '12px', sm: '16px' }}
                  >
                    <Flex alignItems="center" gap={1}>
                      {rule.description} {rule.check && <Icon as={FiCheck} />}
                    </Flex>
                  </ListItem>
                ))}
              </Box>
            </UnorderedList>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                updatePassword.mutate();
              }}
            >
              <FormControl marginTop={{ base: 8, sm: 4 }}>
                <FormLabel
                  marginBottom={{ base: 2, sm: 1 }}
                  fontSize={{ base: '12px', sm: '16px' }}
                >
                  Senha
                </FormLabel>
                <PasswordInput
                  placeholder="Defina uma senha"
                  onChange={handlePasswordChange}
                />
              </FormControl>

              <Button
                type="submit"
                width="100%"
                height={{ base: '42px', sm: '56px' }}
                padding={{ base: '12px 16px', sm: 'unset' }}
                fontSize={{ base: '14px', sm: '16px' }}
                marginTop={4}
                isDisabled={!allRulesChecked}
                isLoading={updatePassword.isPending}
              >
                Confirmar
              </Button>
            </form>
          </CardBody>
          <CardFooter paddingTop={0}>
            <Text fontSize={{ base: '12px', sm: '14px' }}>
              Ao continuar, você confirma que leu e aceita os{' '}
              <PortalTermsOfUseLink color={'primary.100'} />
            </Text>
          </CardFooter>
        </Card>
      </Box>
    </FullScreenBgFirstAccessLayout>
  );
};

export default PasswordSettingPage;
