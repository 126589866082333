import { z, RefinementCtx } from 'zod';
import {
  DOCUMENT_TYPE_OPTIONS,
  SEND_CARD_OPTIONS,
  SEND_COMMS_OPTIONS,
  UFs,
} from '../../constants/general';
import { employeeFullNameSchema } from './employee';
import { cpfValidationSchema } from './cpfValidationSchema';
import { CardTypeEnum } from '../card';
import { employeeNameRegex, validDDDs } from '../../utils/validations/regex';

const checkAndAddIssue = (
  ctx: RefinementCtx,
  value: unknown,
  message: string,
  path: string[]
) => {
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message,
      path,
    });
  }
};

const EMAIL_REQUIRED_MESSAGE = 'Você precisa preencher um e-mail.';
const PHONE_OR_EMAIL_REQUIRED_MESSAGE =
  'Você precisa preencher pelo menos um celular ou um e-mail';

export const UFsSchema = Array.from(UFs.map((uf) => uf.value));
export const SEND_CARD_OPTIONS_ENUM = z.enum(SEND_CARD_OPTIONS);

enum formStepsEnum {
  employeeInfo = 0,
  benefitPackage = 1,
  sendComms = 2,
  sendCard = 3,
}

const formStepSchema = z.object({
  activeFormStep: z.nativeEnum(formStepsEnum),
});

export const employeeInfoSchema = z
  .object({
    customerId: z.string().or(z.literal('')).nullish(),
    deliveryType: z.enum(SEND_CARD_OPTIONS).optional(),
    companyId: z.string().or(z.literal('')).nullish(),
    packageId: z.string().or(z.literal('')).optional(),
    companyAddressId: z.string().or(z.literal('')).nullish(),
    isPhoneRequired: z.boolean().optional(),
    collaboratorData: z.object({
      registration: z.string().optional().or(z.literal('')),
      alternativePhone: z.string().optional().or(z.literal('')),
      admissionDate: z.string().optional().or(z.literal('')),
      personalEmail: z.string().optional().or(z.literal('')),
      fullName: employeeFullNameSchema,
      document: cpfValidationSchema,
      documentType: z.enum(DOCUMENT_TYPE_OPTIONS).optional(),
      socialName: z
        .string()
        .min(1)
        .regex(
          employeeNameRegex,
          'O nome social só pode conter letras e precisa de pelo menos um sobrenome'
        )
        .or(z.literal(''))
        .nullish(),
      email: z
        .string({
          required_error: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        })
        .trim()
        .email({ message: 'Parece que este e-mail está incorreto' })
        .optional()
        .or(z.literal('')),
      cellphone: z
        .string({
          required_error: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        })
        .min(11, 'Por favor, digite o celular com DDD + 9 números')
        .refine((data) => validDDDs.test(data), {
          message: 'Ops... parece que o DDD deste celular não está certo',
        })
        .refine((data) => data.charAt(2) === '9', {
          message: 'O celular precisa começar com o número 9 após o DDD',
        })
        .optional()
        .or(z.literal('')),
      address: z
        .object({
          city: z.string().optional().or(z.literal('')),
          zipCode: z
            .string()
            .min(8, 'Parece que este CEP é inválido')
            .optional()
            .or(z.literal('')),
          country: z.string().optional().or(z.literal('')),
          address: z.string().optional().or(z.literal('')),
          complement: z.string().or(z.literal('')).nullish(),
          number: z.string().or(z.literal('')).nullish(),
          district: z.string().optional().or(z.literal('')),
          addressWithoutComplement: z.boolean().optional().or(z.literal('')),
          addressWithoutNumber: z.boolean().optional().or(z.literal('')),
          state: z.string().optional().or(z.literal('')),
        })
        .optional(),
    }),
  })
  .superRefine((values, ctx) => {
    if (values.isPhoneRequired && !values.collaboratorData?.cellphone) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa informar um celular',
        path: ['collaboratorData.cellphone'],
      });
      return;
    }
    if (
      !values.collaboratorData?.cellphone &&
      !values.collaboratorData?.alternativePhone &&
      !values.collaboratorData?.email &&
      !values.collaboratorData?.personalEmail
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        path: ['collaboratorData.email'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        path: ['collaboratorData.cellphone'],
      });
    }
  });
export const SEND_COMMS_OPTIONS_ENUM = z.enum(SEND_COMMS_OPTIONS);

export const sendCommsFormSchema = z
  .object({
    sendCommunication: SEND_COMMS_OPTIONS_ENUM.optional(),
    sendCommunicationDate: z.string().optional().or(z.literal('')),
  })
  .superRefine((values, ctx) => {
    if (
      values.sendCommunication === 'scheduled' &&
      !values.sendCommunicationDate
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher uma data',
        path: ['sendCommunicationDate'],
      });
    }
  });

export const categorizeEmployeeFormSchema = z.object({
  convention: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .optional(),
  costCenters: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
  groups: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
});

export const addressVisibilitySchema = z.object({
  isAddressVisible: z.boolean().default(false).optional(),
});

export const sendCardFormSchema = z
  .object({
    deliveryType: SEND_CARD_OPTIONS_ENUM.optional(),
  })
  .and(addressVisibilitySchema);

export const benefitPackageSchema = z.object({
  packageId: z.string().optional(),
});

export const cardTypeSchema = z.object({
  isNoNameCardsAllowed: z.boolean().optional(),
  cardType: z.string().optional(),
});

export const newCardSchema = z
  .object({
    companyAddressId: z.string().optional(),
    collaboratorData: z
      .object({
        address: z.object({
          id: z.string().optional(),
          city: z.string().optional().or(z.literal('')),
          zipCode: z
            .string()
            .min(8, 'Parece que este CEP é inválido')
            .optional()
            .or(z.literal('')),
          country: z.string().optional().or(z.literal('')),
          address: z.string().optional().or(z.literal('')),
          complement: z.string().or(z.literal('')).nullish(),
          number: z.string().or(z.literal('')).nullish(),
          district: z.string().optional().or(z.literal('')),
          addressWithoutComplement: z.boolean().optional().or(z.literal('')),
          addressWithoutNumber: z.boolean().optional().or(z.literal('')),
          state: z.string().optional().or(z.literal('')),
        }),
      })
      .optional(),
  })
  .optional()
  .and(cardTypeSchema)
  .and(sendCardFormSchema)
  .superRefine((values, ctx) => {
    if (values.cardType === CardTypeEnum.NOMINAL && !values.deliveryType) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Você precisa selecionar um endereço de entrega ou cadastrar um novo endereço.',
        path: ['deliveryType'],
      });
    }
    if (!values.cardType && !values.deliveryType) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Você precisa selecionar um endereço de entrega ou cadastrar um novo endereço.',
        path: ['deliveryType'],
      });
    }
  });

export const registerEmployeeFormSchema = employeeInfoSchema
  .and(sendCommsFormSchema)
  .and(categorizeEmployeeFormSchema)
  .and(sendCardFormSchema)
  .and(benefitPackageSchema)
  .and(formStepSchema)
  .and(cardTypeSchema)
  .superRefine((values, ctx) => {
    if (
      (values.activeFormStep === formStepsEnum.sendCard &&
        !values.isNoNameCardsAllowed &&
        !values.deliveryType) ||
      (values.activeFormStep === formStepsEnum.sendCard &&
        values.isNoNameCardsAllowed &&
        values.cardType === CardTypeEnum.NOMINAL &&
        !values.deliveryType)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Você precisa selecionar um endereço de entrega ou cadastrar um novo endereço.',
        path: ['deliveryType'],
      });
    }

    if (
      values.sendCommunication ===
        SEND_COMMS_OPTIONS_ENUM.Enum.onAdmissionDate &&
      !values.collaboratorData?.admissionDate
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Voltar e preencher',
        path: ['sendCommunication'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher a data de admissão',
        path: ['collaboratorData.admissionDate'],
      });
    }

    if (values.deliveryType === 'RESIDENTIAL') {
      checkAndAddIssue(
        ctx,
        values.collaboratorData?.address?.zipCode,
        'Preencha o CEP.',
        ['collaboratorData.address.zipCode']
      );
      checkAndAddIssue(
        ctx,
        values.collaboratorData?.address?.address,
        'Preencha a rua.',
        ['collaboratorData.address.address']
      );

      checkAndAddIssue(
        ctx,
        values.collaboratorData?.address?.district,
        'Preencha o bairro.',
        ['collaboratorData.address.district']
      );
      checkAndAddIssue(
        ctx,
        values.collaboratorData?.address?.city,
        'Preencha a cidade.',
        ['collaboratorData.address.city']
      );
      checkAndAddIssue(
        ctx,
        values.collaboratorData?.address?.state,
        'Preencha o estado.',
        ['collaboratorData.address.state']
      );

      if (
        [
          !values.collaboratorData?.address?.zipCode,
          !values.collaboratorData?.address?.address,
          !values.collaboratorData?.address?.city,
          !values.collaboratorData?.address?.state,
          !values.collaboratorData?.address?.district,
        ].some((value) => value)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Clique aqui para preencher o endereço completo do colaborador.',
          path: ['employeeAddressRequired'],
        });
      }
    } else if (values.collaboratorData?.address) {
      const { zipCode, address, number, complement, district, city, state } =
        values.collaboratorData.address;

      const fieldsArray = [
        zipCode,
        address,
        number,
        complement,
        district,
        city,
        state,
      ];

      if (!fieldsArray.some((value) => value)) {
        return;
      }

      // If any of the fields are filled, all of them must be filled
      if (!zipCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Você precisa preencher o CEP.',
          path: ['collaboratorData.address.zipCode'],
        });
      }

      if (!address) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Você precisa preencher o endereço',
          path: ['collaboratorData.address.address'],
        });
      }

      if (!district) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Você precisa preencher o bairro',
          path: ['collaboratorData.address.district'],
        });
      }

      if (!city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Você precisa preencher a cidade',
          path: ['collaboratorData.address.city'],
        });
      }

      if (!state) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Você precisa preencher o estado',
          path: ['collaboratorData.address.state'],
        });
      }
    }
  });

export type SendCommsFormValues = z.infer<typeof sendCommsFormSchema>;
