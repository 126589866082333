import React, { useMemo, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Flex,
  Box,
  CheckboxGroup,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { useGetCompanyBenefitPackages } from '../../../../api/benefit';
import Spinner from '../../../../components/Spinner';
import CheckboxCard from '../../../../components/CheckboxCard';
import BenefitCard from '../../../../components/BenefitCard';
import { Semibold } from '../../../../components/Typography';
import { Employee, EmployeeProcessing } from '../../../../types/employee';
import { usePatchBulkEmployeeBenefitPackage } from '../../../../api/employees';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

type SetBenefitPackageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  employees: Employee[] | EmployeeProcessing[];
  queryKeyToInvalidate?: QueryKey;
};

enum BenefitTypeEnum {
  PACKAGE = 'PACKAGE',
  NO_PACKAGE = 'NO_PACKAGE',
}

const schema = z
  .object({
    benefitType: z.nativeEnum(BenefitTypeEnum),
    packageId: z.string().nullable(),
  })
  .superRefine((values, ctx) => {
    if (values.benefitType === BenefitTypeEnum.PACKAGE && !values.packageId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['packageId'],
        message: 'Você precisa selecionar um pacote de benefícios.',
      });
    }
  });

type FormValues = z.infer<typeof schema>;

type EmptyStateNoBenefitPackagesProps = Pick<
  SetBenefitPackageModalProps,
  'onClose'
>;

const EmptyStateNoBenefitPackages = ({
  onClose,
}: EmptyStateNoBenefitPackagesProps) => {
  const navigate = useNavigate();
  return (
    <>
      <ModalBody>
        <Flex direction="column" gap={4}>
          <Text>
            Por enquanto, você <Semibold>não possui</Semibold> pacotes de
            benefícios cadastrados
          </Text>
          <Text>
            Você deverá entrar na página de Pacotes de Benefícios para criar um
            novo pacote
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          width="375px"
          onClick={() => {
            onClose();
            navigate('/pacotes-de-beneficios');
          }}
        >
          Ir para a página de Pacotes de Benefícios
        </Button>
      </ModalFooter>
    </>
  );
};

const SetBenefitPackageModal = ({
  isOpen,
  onClose,
  employees,
  queryKeyToInvalidate,
}: SetBenefitPackageModalProps) => {
  const { data, isPending } = useGetCompanyBenefitPackages();
  const benefitPackages = data?.data || [];

  const employeesIds = useMemo(
    () => employees.map((employee) => employee.id),
    [employees]
  );

  const { setValue: setCheckboxValue, getCheckboxProps } = useCheckboxGroup();

  const errorMessageRef = useRef<HTMLDivElement>(null);

  const {
    setValue,
    formState: { errors },
    watch,
    trigger,
    getValues,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: {
      benefitType: BenefitTypeEnum.PACKAGE,
      packageId: null,
    },
  });

  // custom function to allow only one checkbox to be checked at a time
  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nextValue = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setCheckboxValue([nextValue]);
      setValue('packageId', nextValue, { shouldDirty: true });
    } else {
      setCheckboxValue([]);
      setValue('packageId', null, { shouldDirty: true });
    }
    trigger('packageId');
  }

  const patchBulkEmployeeBenefitPackage = usePatchBulkEmployeeBenefitPackage();

  const { benefitType, packageId } = watch();

  const queryClient = useQueryClient();

  function onSubmit(values: FormValues) {
    const { packageId } = values;
    patchBulkEmployeeBenefitPackage.mutate(
      {
        employeesIds,
        packageId,
      },
      {
        onSuccess: () => {
          if (queryKeyToInvalidate) {
            queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate });
          }
        },
      }
    );
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={benefitPackages.length > 0 ? '5xl' : 'md'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="20px" fontWeight={500}>
          {employees.length > 1
            ? `Colaboradores selecionados (${employees.length})`
            : `Colaborador selecionado: ${employees[0].name}`}
        </ModalHeader>
        <ModalCloseButton />
        {isPending ? (
          <Spinner />
        ) : benefitPackages.length > 0 ? (
          <>
            <ModalBody maxHeight="70vh" overflow="auto">
              <Box>
                <Text fontWeight={500} fontSize="18px" lineHeight={1}>
                  Escolher benefícios
                </Text>

                <Text marginY={4}>
                  Um <Semibold>pacote</Semibold> define os{' '}
                  <Semibold>benefícios e valores de recarga</Semibold> padrão
                  que um grupo de colaboradores recebe.
                </Text>

                <RadioGroup
                  onChange={(value) => {
                    setValue('benefitType', value as BenefitTypeEnum, {
                      shouldDirty: true,
                    });
                    if (value === BenefitTypeEnum.NO_PACKAGE) {
                      setValue('packageId', null, { shouldDirty: true });
                    }
                    trigger('packageId');
                  }}
                  value={benefitType}
                >
                  <Stack direction="row" spacing={6}>
                    <Radio value={BenefitTypeEnum.PACKAGE}>
                      Pacote de benefícios
                    </Radio>
                    <Radio value={BenefitTypeEnum.NO_PACKAGE}>
                      Sem pacote definido
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex
                  gap={6}
                  flexWrap="wrap"
                  position="relative"
                  marginY={4}
                  id="beneficios"
                >
                  {benefitType === BenefitTypeEnum.NO_PACKAGE && (
                    <Box
                      id="benefit-checkbox-disabled-overlay"
                      position="absolute"
                      zIndex={1}
                      width="100%"
                      height="105%"
                      bg="white"
                      opacity={0.7}
                    />
                  )}
                  <CheckboxGroup>
                    {benefitPackages.length > 0 &&
                      benefitPackages.map((benefit) => {
                        return (
                          <CheckboxCard
                            key={benefit.id}
                            {...getCheckboxProps({ value: benefit.id })}
                            isChecked={packageId === benefit.id}
                            onChange={handleCheckboxChange}
                            isDisabled={
                              benefitType === BenefitTypeEnum.NO_PACKAGE
                            }
                            height="100%"
                            boxHeight="auto"
                          >
                            <BenefitCard benefit={benefit} />
                          </CheckboxCard>
                        );
                      })}
                  </CheckboxGroup>
                </Flex>
              </Box>
              <Box ref={errorMessageRef} marginTop={8} minHeight="24px">
                <ErrorMessage
                  errors={errors}
                  name="packageId"
                  render={({ message }) => (
                    <Text color="feedback.alert">{message}</Text>
                  )}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                width="fit-content"
                mr={3}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                width="220px"
                onClick={() => {
                  trigger().then((isValid) => {
                    if (isValid) {
                      const values = getValues();
                      onSubmit(values);
                    } else {
                      errorMessageRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  });
                }}
                isLoading={patchBulkEmployeeBenefitPackage.isPending}
              >
                Definir pacote
              </Button>
            </ModalFooter>
          </>
        ) : (
          <EmptyStateNoBenefitPackages onClose={onClose} />
        )}
      </ModalContent>
    </Modal>
  );
};

export default SetBenefitPackageModal;
