import {
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Drawer,
  DrawerContent,
  DrawerBody,
  Button,
  HStack,
  Box,
  Center,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import Pagination from '../../../../components/Pagination';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import ActionDrawerInfoCard from '../ActionDrawerInfoCard';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { sumBenefitValuesPerEmployee } from '../../utils';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { Employee } from '../../../../types/employee';
import BenefitDisplay from '../../../../components/BenefitDisplay';
import { BenefitCategories, BenefitCategory } from '../../../../types/benefit';
import { DocumentSignedIcon } from '../../../../assets/customIcons/DocumentSignedIcon';
import ReviewCurrencyInput from '../ReviewCurrencyInput';
import RechargeStepper from '../RechargeStepper';
import { PrepareRechargeData } from '../../../../types/recharge';
import { format } from 'date-fns';
import { FiCalendar } from 'react-icons/fi';

export type RechargeTableProps = {
  employees: Employee[];
  rechargeCompetencyInDays: number | undefined;
};

const columnHelper = createColumnHelper<Employee>();

const RechargeReview = () => {
  const location = useLocation();
  const employees = location.state?.employees as Employee[] | undefined;
  const grandTotal = location.state?.grandTotal as number | 0;
  const numOfDays = location.state?.numOfDays as number;
  const isSpreadsheetImport = location.state?.isSpreadsheetImport as
    | boolean
    | undefined;

  const rechargeCreditDates = location.state?.rechargeCreditDates as
    | PrepareRechargeData['creditDates']
    | undefined;

  const hasMultipleCreditDates =
    rechargeCreditDates && rechargeCreditDates.length > 1;

  const benefitCategories: BenefitCategories[] = useMemo(() => {
    const categories = location.state?.benefitCategories as
      | BenefitCategories[]
      | undefined;

    if (!categories) {
      return [];
    }

    const categoriesToDisplay = new Set<string>();

    if (employees) {
      employees.map((employee) => {
        employee.categories.map((category) => {
          if (category.value > 0) {
            categoriesToDisplay.add(category.id);
          }
        });
      });
      const filteredCategories = categories.filter((category) =>
        categoriesToDisplay.has(category.id)
      );
      return filteredCategories;
    }

    return [];
  }, [location?.state?.benefitCategories, employees]);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);

  const navigate = useNavigate();

  const rechargeTableColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: 'Nome',
        enableColumnFilter: true,
        sortingFn: (a, b) => {
          return a.original.name.localeCompare(b.original.name);
        },
        meta: {
          minWidth: '250px',
          position: 'sticky',
          left: 0,
          zIndex: 1,
          boxShadow: '-17px 10px 3px -18px rgba(0,0,0,0.50) inset',
          background: 'gray.50',
        },
      }),
      ...(benefitCategories.length > 0
        ? benefitCategories.map((category) => {
            return columnHelper.accessor(
              (row) => {
                const categoryIndex = row.categories.findIndex(
                  (cat) => cat.id === category.id
                );
                return row.categories[categoryIndex];
              },
              {
                cell: (info) => {
                  const benefit = info.getValue();
                  return <ReviewCurrencyInput value={benefit.value} />;
                },
                id: category.id,
                enableColumnFilter: false,
                enableSorting: false,
                header: () => {
                  return (
                    <BenefitDisplay
                      benefit={
                        {
                          category: category as unknown as BenefitCategory,
                        } as BenefitCategories
                      }
                    />
                  );
                },
              }
            );
          })
        : []),
    ];
  }, [benefitCategories]);

  const table = useReactTable({
    data: employees || [],
    columns: rechargeTableColumns,
    state: {
      sorting: sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const visibleRows = table.getRowModel().rows ?? [];

  if (!employees || !grandTotal || !benefitCategories) {
    return <Navigate to="/recargas" />;
  }

  const numOfEmployees = employees.length;

  if (numOfEmployees === 0) {
    return <Navigate to="/recargas" />;
  }

  return (
    <>
      <RechargeStepper isSpreadsheetImport={isSpreadsheetImport} />

      <Box marginTop={6}>
        <Flex direction="column" gap={4} marginBottom="140px">
          <Card
            padding={0}
            maxWidth="100%"
            overflowX="auto"
            overflowY="auto"
            maxHeight="calc(80vh - 200px)"
          >
            <Table>
              <Thead
                position="sticky"
                top={0}
                zIndex="docked"
                background="gray.50"
                boxShadow="0px 5px 5px -8px rgba(0,0,0,0.75)"
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          width={header.id !== 'name' ? '270px' : 'auto'}
                          key={header.id}
                          cursor={
                            header.column.getCanSort() ? 'pointer' : 'unset'
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          userSelect="none"
                          {...header.column.columnDef.meta}
                        >
                          <Flex
                            alignItems="flex-end"
                            gap={2}
                            height="100%"
                            marginTop={4}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getCanSort() && (
                              <SortDirectionIndicator
                                direction={header.column.getIsSorted()}
                              />
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                    <Th>
                      <Flex
                        alignItems="flex-end"
                        gap={1}
                        fontWeight={600}
                        color="primary.primary"
                        marginTop={4}
                      >
                        <DocumentSignedIcon />
                        <Text minWidth="130px">Valor total</Text>
                      </Flex>
                    </Th>
                    {hasMultipleCreditDates && (
                      <Th>
                        <Flex
                          alignItems="flex-end"
                          gap={1}
                          fontWeight={600}
                          marginTop={4}
                          width="210px"
                        >
                          <FiCalendar size="16px" />
                          <Text>Data de crédito</Text>
                        </Flex>
                      </Th>
                    )}
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    _hover={{
                      background: 'gray.50',
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        {...cell.column.columnDef.meta}
                        minWidth="200px"
                        zIndex={2}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                    <Td id={'total-value-per-employee-' + row.original.id}>
                      <ReviewCurrencyInput
                        value={sumBenefitValuesPerEmployee(row.original)}
                      />
                    </Td>
                    {hasMultipleCreditDates && row.original.creditDate && (
                      <Td id={'credit-date-per-employee' + row.original.id}>
                        <Center
                          width="140px"
                          height="40px"
                          background="gray.100"
                          borderRadius="8px"
                        >
                          <Text>
                            {format(
                              new Date(row.original.creditDate),
                              'dd/MM/yyyy'
                            )}
                          </Text>
                        </Center>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>

          <Flex justifyContent="space-between" alignItems="center">
            <Pagination
              currentPage={table.getState().pagination.pageIndex + 1}
              totalPages={table.getPageCount()}
              onChangeRowsPerPage={(option) =>
                table.setPageSize(option?.value ?? 10)
              }
              handleClickFirstPage={() => table.setPageIndex(0)}
              handleClickNextPage={() => table.nextPage()}
              handleClickPreviousPage={() => table.previousPage()}
              handleClickLastPage={() =>
                table.setPageIndex(table.getPageCount() - 1)
              }
              elementsOnPage={visibleRows.length}
              totalElements={employees?.length ?? 0}
            />
          </Flex>
        </Flex>
        <Drawer
          placement="bottom"
          isOpen={true}
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
          variant="clickThrough"
          onClose={() => {}}
        >
          <DrawerContent zIndex={1} paddingX={20} height="144px">
            <DrawerBody>
              <Flex
                alignItems="center"
                gap={4}
                height="100%"
                justifyContent="space-between"
                direction={'row-reverse'}
              >
                <Flex gap={4}>
                  <Button
                    width="fit-content"
                    variant="outline"
                    onClick={() =>
                      navigate('/recargas/nova-recarga', {
                        state: {
                          selectedEmployees: employees,
                          rechargeCompetencyInDays: numOfDays,
                          isSpreadsheetImport,
                          rechargeCreditDates,
                        },
                      })
                    }
                  >
                    Voltar e editar
                  </Button>
                  <Button
                    onClick={() =>
                      navigate('/recargas/nova-recarga/finalizar-pedido', {
                        state: {
                          employees,
                          grandTotal,
                          isSpreadsheetImport,
                          rechargeCreditDates,
                        },
                      })
                    }
                  >
                    Continuar
                  </Button>
                </Flex>
                <HStack spacing={4}>
                  <ActionDrawerInfoCard
                    label="Colaboradores na recarga"
                    value={numOfEmployees}
                  />
                  <ActionDrawerInfoCard
                    label="Valor total"
                    value={formatCurrencyInCents(grandTotal)}
                  />
                </HStack>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default RechargeReview;
