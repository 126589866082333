import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { BenefitPackage } from '../../../../types/benefit';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ErrorMessage } from '@hookform/error-message';
import InputWithConfirmation from '../../../../components/InputWithConfirmation';
import { benefitsPresentation } from '../../../../utils/formatters/benefitsPresentation';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import DrawerAlert from '../DrawerAlert';
import {
  useCreateBenefitPackage,
  useUpdateBenefitPackage,
} from '../../../../api/benefit';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import CustomTooltip from '../../../../components/CustomTooltip';
import { RiQuestionLine } from 'react-icons/ri';
import { benefitPackageCreationSchema } from '../../../../types/schemas/benefitPackageCreationSchema';
import Title from '../../../../components/Title';
import {
  EmployeeBenefitCategory,
  UsageTypeEnum,
} from '../../../../types/employee';

type CreateAndEditBenefitPackageProps = {
  companyBenefitCategories: EmployeeBenefitCategory[] | undefined;
  benefitPackageToEdit?: BenefitPackage | null;
  isOpen: boolean;
  onClose: () => void;
  companyPackagesNames: string[];
};

type FormValues = z.infer<typeof benefitPackageCreationSchema>;

const CreateAndEditBenefitPackage = ({
  companyBenefitCategories,
  benefitPackageToEdit,
  isOpen,
  onClose,
  companyPackagesNames,
}: CreateAndEditBenefitPackageProps) => {
  const alertModal = useDisclosure();

  const getMissingPackageCategories = () => {
    const missingCategories: EmployeeBenefitCategory[] = [];
    const searchPackage = benefitPackageToEdit?.package_categories?.filter(
      (benefit) =>
        !companyBenefitCategories?.some(
          (category) => category.id === benefit.category.id
        )
    );
    if (searchPackage?.length ?? 0 > 0) {
      searchPackage?.forEach((benefit) =>
        missingCategories.push({
          ...benefit.category,
          period: 'DAILY',
          value: benefit.value as unknown as number,
        })
      );
    }
    return missingCategories;
  };

  const missingPackages = getMissingPackageCategories();

  const combinePackageCategories = missingPackages?.concat(
    companyBenefitCategories ?? []
  );

  const benefitCategoriesList = combinePackageCategories?.map((category) => {
    if (!benefitPackageToEdit) {
      return {
        ...category,
        periodType: 'MONTHLY',
        value: 0,
      };
    } else {
      const currentBenefit = benefitPackageToEdit.package_categories.find(
        (benefit) => benefit.category.id === category.id
      );
      return {
        ...category,
        periodType: currentBenefit?.periodType || 'MONTHLY',
        value: currentBenefit?.value ? parseInt(currentBenefit.value) : 0,
      };
    }
  });

  const restrictCategories = benefitCategoriesList.filter(
    (category) => category.usageType === UsageTypeEnum.RESTRICT
  );

  const freeUsageCategories = benefitCategoriesList.filter(
    (category) => category.usageType === UsageTypeEnum.FREE_USAGE
  );

  const forbiddenPackageNames = companyPackagesNames.map((name) => {
    if (name === benefitPackageToEdit?.name) return;
    return name.toLocaleLowerCase();
  });

  const {
    control,
    watch,
    reset,
    clearErrors,
    getValues,
    trigger,
    register,
    setFocus,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(
      benefitPackageCreationSchema.superRefine((values, ctx) => {
        if (forbiddenPackageNames.includes(values.name.toLocaleLowerCase())) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Já existe um pacote registrado com esse nome',
            path: ['name'],
          });
        }
      })
    ),
    mode: 'onBlur',
    values: {
      name: benefitPackageToEdit?.name || '',
      restrictCategories: restrictCategories,
      freeUsageCategories: freeUsageCategories,
    },
  });

  const { selectedCompany } = useUserCompaniesStore();

  const watchedrestrictCategories = watch('restrictCategories') || [];
  const watchedfreeUsageCategories = watch('freeUsageCategories') || [];

  const watchedCategories = watchedrestrictCategories.concat(
    watchedfreeUsageCategories
  );

  const total =
    watchedCategories.reduce((acc, category) => {
      const value = category.value || 0;
      const multiplier = category.periodType === 'DAILY' ? 20 : 1;
      return acc + value * multiplier;
    }, 0) || 0;

  const dirtyFieldsKeys = Object.keys(dirtyFields);

  const createBenefitPackage = useCreateBenefitPackage();
  const updateBenefitPackage = useUpdateBenefitPackage();

  function onCloseModal() {
    alertModal.onClose();
    reset();
    onClose();
    clearErrors();
  }

  function onSubmit(data: FormValues) {
    if (errors.name) {
      setFocus('name');
    }
    trigger().then((isValid) => {
      if (!isValid) return;
      if (benefitPackageToEdit) {
        updateBenefitPackage.mutate(
          {
            benefitPackage: {
              id: benefitPackageToEdit.id,
              customerId: selectedCompany?.customerId || '',
              package_categories: watchedCategories,
              name: data.name,
            },
          },
          {
            onSuccess: () => {
              onCloseModal();
            },
          }
        );
      } else {
        createBenefitPackage.mutate(
          {
            benefitPackage: {
              customerId: selectedCompany?.customerId || '',
              package_categories: watchedCategories,
              name: data.name,
            },
          },
          {
            onSuccess: () => {
              onCloseModal();
            },
          }
        );
      }
    });
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          if (dirtyFieldsKeys.length > 0) {
            alertModal.onOpen();
          } else {
            onClose();
          }
        }}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Title>
              {benefitPackageToEdit ? 'Editar' : 'Criar novo'} pacote de
              benefícios
            </Title>
          </DrawerHeader>

          <DrawerBody>
            <Text marginBottom={8} lineHeight="130%">
              Você pode definir no menu Configurações quais categorias você quer
              oferecer aos colaboradores
            </Text>
            <form>
              <Stack spacing={4}>
                <FormControl mb={2} isInvalid={!!errors.name}>
                  <FormLabel fontSize="sm">Nome do pacote</FormLabel>
                  <Input
                    {...register('name', {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  <FormErrorMessage>
                    <ErrorMessage errors={errors} name="name" />
                  </FormErrorMessage>
                </FormControl>

                <Flex width="100%" justifyContent="flex-end">
                  <CustomTooltip label="Quando o valor de um benefício é configurado por dia, ao iniciar uma recarga você deverá informar a quantidade de dias que devemos considerar para calcular o valor sugerido da recarga deste benefício.">
                    <Flex
                      as="span"
                      alignItems="center"
                      minHeight="100%"
                      position="relative"
                      lineHeight="inherit"
                      gap={2}
                    >
                      <Icon as={RiQuestionLine} color="primary.200" />
                      <Text
                        color="primary.200"
                        textDecor="underline"
                        fontWeight={600}
                        cursor="default"
                      >
                        O que acontece se eu configurar um valor por dia?
                      </Text>
                    </Flex>
                  </CustomTooltip>
                </Flex>

                <Flex flexDirection="column" gap={4} marginTop={4}>
                  {restrictCategories.map((benefit, index) => {
                    const icon =
                      benefitsPresentation[
                        benefit.name as keyof typeof benefitsPresentation
                      ].icon;

                    const color =
                      benefitsPresentation[
                        benefit.name as keyof typeof benefitsPresentation
                      ].color;

                    return (
                      <Flex
                        key={benefit.name}
                        gap={4}
                        justifyContent="space-between"
                        backgroundColor="gray.50"
                        padding={2}
                      >
                        <Flex alignItems="center" gap={2}>
                          <Icon as={icon} color={color} />
                          <Text color={color} fontWeight={600}>
                            {benefit.name}
                          </Text>
                        </Flex>

                        <Flex gap={4}>
                          <Controller
                            name={`restrictCategories.${index}.value`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <>
                                  <Box minWidth="200px">
                                    <InputWithConfirmation
                                      isInvalid={!!errors.restrictCategories}
                                      title=""
                                      minWidth="200px"
                                      type="currency"
                                      defaultValue={formatCurrencyInCents(
                                        field.value || '0'
                                      )}
                                      value={field.value?.toString()}
                                      onSubmit={(value) => {
                                        field.onChange(parseInt(value));
                                        trigger('restrictCategories');
                                      }}
                                    />
                                  </Box>
                                </>
                              );
                            }}
                          />

                          <Controller
                            name={`restrictCategories.${index}.periodType`}
                            control={control}
                            render={({ field }) => (
                              <FormControl
                                display="flex"
                                maxWidth="fit-content"
                                borderRadius={6}
                                border="1px solid"
                                borderColor="gray.300"
                                padding="8px 16px"
                                gap={2}
                                alignItems="center"
                                backgroundColor="white"
                              >
                                <Checkbox
                                  size="lg"
                                  isChecked={field.value === 'DAILY'}
                                  onChange={(e) =>
                                    field.onChange(
                                      e.target.checked ? 'DAILY' : 'MONTHLY'
                                    )
                                  }
                                />
                                <FormLabel
                                  paddingTop="2px"
                                  lineHeight={1}
                                  userSelect="none"
                                >
                                  Valor por dia
                                </FormLabel>
                              </FormControl>
                            )}
                          />
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>

                {freeUsageCategories.length > 0 && (
                  <Flex flexDirection="column" gap={4}>
                    <Stack spacing={4}>
                      <Text fontWeight={700} fontSize="lg" lineHeight="130%">
                        Categorias de uso livre
                      </Text>
                      <Text>
                        Essas categorias podem ser usadas para recarga de
                        valores que não sejam benefícios
                      </Text>
                    </Stack>
                    {freeUsageCategories.map((benefit, index) => {
                      const icon =
                        benefitsPresentation[
                          benefit.name as keyof typeof benefitsPresentation
                        ].icon;

                      const color =
                        benefitsPresentation[
                          benefit.name as keyof typeof benefitsPresentation
                        ].color;

                      return (
                        <Flex
                          key={benefit.name}
                          gap={4}
                          justifyContent="space-between"
                          backgroundColor="gray.50"
                          padding={2}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Icon as={icon} color={color} />
                            <Text color={color} fontWeight={600}>
                              {benefit.name}
                            </Text>
                          </Flex>

                          <Flex gap={4}>
                            <Controller
                              name={`freeUsageCategories.${index}.value`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <>
                                    <Box minWidth="200px">
                                      <InputWithConfirmation
                                        isInvalid={!!errors.freeUsageCategories}
                                        title=""
                                        minWidth="200px"
                                        type="currency"
                                        defaultValue={formatCurrencyInCents(
                                          field.value || '0'
                                        )}
                                        value={field.value?.toString()}
                                        onSubmit={(value) => {
                                          field.onChange(parseInt(value));
                                          trigger('freeUsageCategories');
                                        }}
                                      />
                                    </Box>
                                  </>
                                );
                              }}
                            />

                            <Controller
                              name={`freeUsageCategories.${index}.periodType`}
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  display="flex"
                                  maxWidth="fit-content"
                                  borderRadius={6}
                                  border="1px solid"
                                  borderColor="gray.300"
                                  padding="8px 16px"
                                  gap={2}
                                  alignItems="center"
                                  backgroundColor="gray.50"
                                >
                                  <Checkbox
                                    size="lg"
                                    isChecked={field.value === 'DAILY'}
                                    onChange={(e) =>
                                      field.onChange(
                                        e.target.checked ? 'DAILY' : 'MONTHLY'
                                      )
                                    }
                                  />
                                  <FormLabel
                                    paddingTop="2px"
                                    lineHeight={1}
                                    userSelect="none"
                                  >
                                    Valor por dia
                                  </FormLabel>
                                </FormControl>
                              )}
                            />
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                )}

                <ErrorMessage
                  name="package_categories"
                  errors={errors}
                  render={(data) => (
                    <Text marginTop={2} color="feedback.alert">
                      {data.message}
                    </Text>
                  )}
                />

                <Flex
                  justifyContent="space-between"
                  alignItems="flex-end"
                  marginTop={4}
                >
                  <Button
                    width="100%"
                    maxWidth="298px"
                    isLoading={
                      createBenefitPackage.isPending ||
                      updateBenefitPackage.isPending
                    }
                    onClick={() => {
                      const values = getValues();
                      onSubmit(values);
                    }}
                  >
                    Salvar alterações
                  </Button>

                  <Box>
                    <Box
                      backgroundColor="gray.100"
                      paddingY={4}
                      paddingX={6}
                      width="353px"
                      borderRadius="lg"
                    >
                      <Text>Soma de todos os benefícios</Text>
                      <Text fontWeight={600} fontSize="20px">
                        {formatCurrencyInCents(total)}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                <Text textAlign="end" fontSize="14px">
                  O valor é uma estimativa considerando 20 dias.
                </Text>
              </Stack>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {alertModal.isOpen && (
        <DrawerAlert
          isOpen={alertModal.isOpen}
          onClose={alertModal.onClose}
          onCloseModal={onCloseModal}
          isEdit={!!benefitPackageToEdit}
        />
      )}
    </>
  );
};

export default CreateAndEditBenefitPackage;
