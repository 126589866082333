import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useToast } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import {
  RechargeResultById,
  RechargeDates,
  RechargePaymentMethod,
  RechargeVisibilityEnum,
  EditRechargeResultResponse,
  NewRechargeResultResponse,
  RechargePollingResponse,
  PrepareRechargeData,
  DocumentTypeEnum,
} from '../../types/recharge';
import { Employee } from '../../types/employee';
import { useNavigate } from 'react-router-dom';
import { CompanyAccountBalance } from '../../types/credits';
import { downloadFileByUrl } from '../../utils/downloadFileByUrl';

type RechargeBenefitsPayload = {
  paymentMethod?: string;
  creditAvailability?: string;
  scheduledDate?: string;
  employees?: Partial<Employee>[];
  dueDate?: string;
  rechargeVisibilityConfig?: RechargeVisibilityEnum;
  observation?: string;
};

type EditRechargePayload = RechargeBenefitsPayload & {
  creditAvailability: string;
  rechargeVisibilityConfig: RechargeVisibilityEnum;
  dueDate?: string;
  changeDueDate: boolean;
};

type DromoRechargeData = {
  [key: string]: string;
}[];

export const useCreateRecharge = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: (payload: RechargeBenefitsPayload) =>
      api.post<NewRechargeResultResponse>('/api/recharges', {
        ...payload,
        companyId: selectedCompany?.id,
        customerId: selectedCompany?.customerId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account-balance', selectedCompany?.externalCode],
      });
      queryClient.invalidateQueries({
        queryKey: ['recharge-list', selectedCompany?.id],
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao criar recarga.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const usePrepareRechargeData = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const navigate = useNavigate();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ rechargeData }: { rechargeData: DromoRechargeData }) =>
      api.post<PrepareRechargeData>('/api/recharges/prepare-data', {
        rechargeData,
        customerId: selectedCompany?.customerId,
      }),
    onSuccess: (response) => {
      navigate('/recargas/nova-recarga?importacao=1', {
        state: {
          selectedEmployees: response.data.collaborators,
          rechargeCreditDates: response.data.creditDates,
        },
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao importar planilha.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useGetAccountBalance = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['account-balance', selectedCompany?.externalCode],
    queryFn: async () =>
      await api.get<CompanyAccountBalance>(
        `/api/recharges/company/account-balance?customerId=${selectedCompany?.externalCode}`
      ),
    enabled: !!selectedCompany?.externalCode,
  });
};
export const useGetRecharge = (id: string) => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['recharge', selectedCompany?.id, id],
    queryFn: async () =>
      await api.get<RechargeResultById>(
        `/api/recharges/by-id/${id}?companyId=${selectedCompany?.id}`
      ),
  });
};

export const useGetRechargePolling = (
  enabled: boolean,
  id: string | undefined
) => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['rechargeInfoFromPolling', selectedCompany?.id, id],
    queryFn: async () =>
      await api.get<RechargePollingResponse>(
        `/api/recharges/polling/${id}?companyId=${selectedCompany?.id}`
      ),
    select: (response) => {
      return response.data;
    },
    refetchInterval: 5000,
    enabled: enabled && id !== undefined,
  });
};

export const usePatchRecharge = (id: string) => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: (payload: EditRechargePayload) =>
      api.patch<EditRechargeResultResponse>(`/api/recharges/${id}`, {
        ...payload,
        companyId: selectedCompany?.id,
        customerId: selectedCompany?.customerId,
      }),
    onSuccess: () => {
      toast({
        title: 'Recarga editar com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['recharge-list', selectedCompany?.id],
      });
      queryClient.invalidateQueries({
        queryKey: ['recharge', selectedCompany?.id, id],
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao editar recarga.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

type GetRechargeDatesProps = {
  paymentMethod?: string;
  scheduledDate?: string;
};

export const useGetRechargeDates = ({
  paymentMethod,
  scheduledDate,
}: GetRechargeDatesProps) => {
  const { api } = useFetchContext();

  const paymentMethodQueryString = paymentMethod
    ? `paymentMethod=${paymentMethod.toUpperCase()}`
    : '';

  const scheduleDateQueryString = scheduledDate
    ? `&scheduledDate=${scheduledDate}`
    : '';

  return useQuery({
    queryKey: ['recharge-dates', { paymentMethod, scheduledDate }],
    queryFn: async () =>
      await api.get<RechargeDates>(
        `/api/recharges/dates?${paymentMethodQueryString}${scheduleDateQueryString}`
      ),
    select: (response) => response.data,
    enabled: !!paymentMethod,
    staleTime: 60000,
    gcTime: 60000,
  });
};

export const useGetEarliestBilletCreditDate = () => {
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['earliest-billet-credit-date'],
    queryFn: async () =>
      await api.get<RechargeDates>(
        `/api/recharges/dates?paymentMethod=${RechargePaymentMethod.BOLETO}`
      ),
    select: (response) => response.data,
    staleTime: 60000,
    gcTime: 60000,
  });
};

type CancelRechargePayload = {
  id: string;
  reason: string;
  observation: string | undefined | null;
};

export const useCancelRecharge = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: async (rechargeInfos: CancelRechargePayload) =>
      api.patch(`/api/recharges/${rechargeInfos.id}/cancel`, {
        reason: rechargeInfos.reason,
        observation: rechargeInfos.observation,
      }),
    onSuccess: () => {
      toast({
        title: 'Recarga cancelada com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['recharge-list', selectedCompany?.id],
      });
    },
    onError: () => {
      toast({
        title: 'Não conseguimos cancelar esta recarga.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useGetRechargeExternal = (
  isQueryEnabled?: boolean,
  externalCode?: string
) => {
  const enabled = isQueryEnabled === undefined ? true : isQueryEnabled;

  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const queryClient = useQueryClient();

  const queryFn = () =>
    api.get<RechargeResultById>(
      `/api/recharges/external/${externalCode}?companyId=${selectedCompany?.id}`
    );

  const queryKey = ['recharge', selectedCompany?.id, externalCode];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    select: (response) => response.data,
    enabled: !!externalCode && enabled,
  });

  const prefetch = async () => {
    await queryClient.prefetchQuery({
      queryKey: queryKey,
      queryFn: queryFn,
      staleTime: 60000,
    });
  };

  return { query, prefetch };
};

type RechargeFileDownloadPayload = {
  rechargeId: string;
  documentType: keyof typeof DocumentTypeEnum;
};

type RechargeFileDownloadResponse = {
  url: string;
};

export const useRechargeFileDownload = () => {
  const { api } = useFetchContext();
  const toast = useToast();

  return useMutation({
    mutationFn: async (payload: RechargeFileDownloadPayload) => {
      return api.post<RechargeFileDownloadResponse>(
        `/api/recharges/download`,
        payload
      );
    },
    onSuccess: (response) => {
      toast({
        title: 'Pedido de download criado com sucesso!',
        status: 'success',
      });

      const signedUrl = response.data.url;
      downloadFileByUrl(signedUrl);
    },
    onError: () => {
      toast({
        title: 'Não foi possível fazer o download.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useGetRechargeFileSignedURL = () => {
  const { api } = useFetchContext();

  return useMutation({
    mutationFn: async (payload: RechargeFileDownloadPayload) => {
      return api.post<RechargeFileDownloadResponse>(`/api/recharges/download`, {
        rechargeId: payload.rechargeId,
        documentType: payload.documentType,
      });
    },
  });
};
