import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react';
import { useState } from 'react';
import ToggleDisplayPassword from './ToggleDisplayPassword';

const PasswordInput = forwardRef((props: InputProps, ref) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  return (
    <InputGroup>
      <Input
        data-testid="password-input"
        ref={ref}
        paddingRight={{ base: '16px', sm: '90px' }}
        height={{ base: '40px', sm: 'unset' }}
        fontSize={{ base: '14px', sm: '16px' }}
        type={shouldShowPassword ? 'text' : 'password'}
        autoComplete="current-password"
        placeholder="Digite a sua senha"
        {...props}
      />
      <Box
        position="absolute"
        bottom="46px"
        right="0px"
        display={{ base: 'block', sm: 'none' }}
      >
        <ToggleDisplayPassword
          shouldShowPassword={shouldShowPassword}
          setShouldShowPassword={() =>
            setShouldShowPassword(!shouldShowPassword)
          }
        />
      </Box>
      <InputRightElement
        mr={4}
        width="fit-content"
        display={{ base: 'none', sm: 'flex' }}
      >
        <ToggleDisplayPassword
          dataTestId="show-password-button"
          shouldShowPassword={shouldShowPassword}
          setShouldShowPassword={() =>
            setShouldShowPassword(!shouldShowPassword)
          }
        />
      </InputRightElement>
    </InputGroup>
  );
});

export default PasswordInput;
