import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { Employee } from '../../../../types/employee';
import { useMemo } from 'react';
import { aggregateRechargeDates } from '../../utils/aggregateRechargeDates';

type MultipleCreditDatesCardProps = {
  employees: Employee[];
};

const MultipleCreditDatesCard = ({
  employees,
}: MultipleCreditDatesCardProps) => {
  const creditDates = useMemo(
    () => aggregateRechargeDates(employees),
    [employees]
  );

  return (
    <Box
      padding={8}
      bg="white"
      borderRadius="8px"
      height="100%"
      minHeight="400px"
    >
      <Text fontSize="20px" fontWeight={500}>
        Datas de crédito dos benefícios
      </Text>

      <Box marginTop={8}>
        <Flex
          borderBottom="1px solid"
          borderColor="gray.300"
          paddingY={4}
          fontSize="16px"
          fontWeight={600}
          justifyContent="space-between"
          color="gray.800"
          height="53px"
        >
          <Center minWidth="154px">
            <Text>Data de crédito</Text>
          </Center>
          <Center minWidth="154px">
            <Text>Colaboradores</Text>
          </Center>
          <Center minWidth="154px">
            <Text>Valor</Text>
          </Center>
        </Flex>

        {creditDates.map((creditDate, index) => (
          <Flex
            paddingY={4}
            fontSize="16px"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="gray.100"
            height="53px"
            key={index}
          >
            <Box minWidth="154px" paddingX={5}>
              <Text>{format(new Date(creditDate.date), 'dd/MM/yyyy')}</Text>
            </Box>
            <Center minWidth="154px">
              <Text>{creditDate.numberOfCollaborators}</Text>
            </Center>
            <Center minWidth="154px">
              <Text>{formatCurrencyInCents(creditDate.totalValue)}</Text>
            </Center>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default MultipleCreditDatesCard;
