import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CardStatusEnum } from '../../../../types/card';
import {
  EmployeeProcessing,
  TranslateEmployeeStatusEnum,
} from '../../../../types/employee';
import { getCardStatusText } from '../../utils/getCardStatusText';
import { maskCpfForPrivacy } from '../../../../utils/formatters/maskCpfForPrivacy';
import EmployeeStatusBadge from '../../../../components/EmployeeStatusBadge';
import { isCompleteISOString } from '../../../../utils/validations/isCompleteISOString';
import { format } from 'date-fns';
import BenefitPackageColumn from '../BenefitPackageColumn';

const columnHelper = createColumnHelper<EmployeeProcessing>();

const setCardStatus = (status: keyof typeof CardStatusEnum) => {
  const statusMappings: { [key: string]: ReactElement } = {
    PROCESSING: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
  };
  return statusMappings[status] || <></>;
};

export const registrationProcessingColumns = [
  columnHelper.display({
    id: 'select',
    enableColumnFilter: false,
    header: ({ table }) => (
      <Checkbox
        size="lg"
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        title="Selecionar todos os colaboradores"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="lg"
        isChecked={row.getIsSelected()}
        isIndeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('name', {
    cell: (info) => (
      <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
        {info.getValue()}
      </Text>
    ),
    header: 'Nome',
    enableColumnFilter: true,
    sortingFn: (a, b) => {
      return a.original.name.localeCompare(b.original.name);
    },
    meta: { minWidth: '130px' },
  }),
  columnHelper.accessor('cpf', {
    cell: (info) => {
      const cpf = info.getValue();
      return (
        <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
          {maskCpfForPrivacy(cpf)}
        </Text>
      );
    },
    header: 'CPF',
    enableColumnFilter: true,
    meta: { minWidth: '120px' },
  }),

  columnHelper.accessor('createdAt', {
    cell: (info) => {
      const dateTime = info.getValue();
      if (isCompleteISOString(dateTime)) {
        return format(new Date(dateTime), 'dd/MM/yy HH:mm');
      } else {
        return '';
      }
    },
    header: 'Cadastrado em',
    enableColumnFilter: true,
    meta: { minWidth: '130px' },
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const status =
        info.getValue() as unknown as keyof typeof TranslateEmployeeStatusEnum;
      return <EmployeeStatusBadge status={status} />;
    },
    enableColumnFilter: false,
    header: 'Status do colaborador',
    meta: { maxWidth: '100px' },
  }),

  columnHelper.accessor('packageName', {
    cell: (info) => (
      <BenefitPackageColumn
        employee={info.row.original}
        queryKeyToInvalidate={['processingEmployees']}
      />
    ),
    enableColumnFilter: true,
    header: 'Pacote de benefícios',
    meta: { minWidth: '150px' },
  }),
];
