import { FIRST_ACCESS_STEPS, router } from '../routes';
import { Company } from '../types/company';

export function firstAccessRedirect(company: Company): void {
  if (!company.registrationStep) {
    return;
  }

  const step = company.registrationStep;

  // TODO: backend should change its logic so that the company registrationStep is NEVER 1 because step 1 is relative to the USER setting a password. every new company created should have registrationStep 2 as the first step

  switch (step) {
    case 1:
      // company.registrationStep 1 is relative to the user setting a password
      // if the user reached this redirection logic, it means they have already set a password so they should be redirected to step 2
      router.navigate(FIRST_ACCESS_STEPS[2]);
      break;
    case 2:
      router.navigate(FIRST_ACCESS_STEPS[2]);
      break;
    case 3:
      router.navigate(FIRST_ACCESS_STEPS[3]);
      break;
    case 4:
      router.navigate(FIRST_ACCESS_STEPS[4]);
      break;
    case 5:
      router.navigate(FIRST_ACCESS_STEPS[5]);
      break;
    case 99:
      break;
    default:
      break;
  }
}
