import { CreditTransaction } from '../../types/credits';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useToast } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import {
  CreateCreditOrderPayload,
  CreateCreditOrderResponse,
} from '../../types/credits';
import { globalCustomerConfigStore } from '../../stores/globalCustomerConfigStore';
import { useAuthContext } from '../../hooks/useAuthContext';
import { downloadFileByUrl } from '../../utils/downloadFileByUrl';

export const useGetCompanyTransactions = () => {
  const { api } = useFetchContext();

  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: [
      'company-transactions',
      {
        registrationNumber: selectedCompany?.registrationNumber,
      },
    ],
    queryFn: () =>
      api.get<CreditTransaction[]>(
        `/api/accounts/${selectedCompany?.registrationNumber}/company-transactions`
      ),
    select: (response) => response.data,
  });
};

export const useCreateCreditOrder = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();
  const { globalCustomerConfig } = globalCustomerConfigStore();
  const { user } = useAuthContext();
  const userId = user?.id;

  return useMutation({
    mutationFn: (payload: CreateCreditOrderPayload) =>
      api.post<CreateCreditOrderResponse>('/api/credit-order', {
        ...payload,
        customerId: selectedCompany?.customerId,
        externalCode: selectedCompany?.externalCode,
        companyId: selectedCompany?.id,
        createdBy: userId,
        beneficiaryName: globalCustomerConfig?.paymentBeneficiary.name,
        beneficiaryRegistrationNumber:
          globalCustomerConfig?.paymentBeneficiary.registrationNumber,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'company-transactions',
          {
            registrationNumber: selectedCompany?.registrationNumber,
          },
        ],
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao criar recarga.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

type CreditOrderFileDownloadPayload = {
  orderId: string;
};

type CreditOrderFileDownloadResponse = {
  url: string;
};

export const useCreditOrderFileDownload = () => {
  const { api } = useFetchContext();
  const toast = useToast();

  return useMutation({
    mutationFn: async (payload: CreditOrderFileDownloadPayload) =>
      api.post<CreditOrderFileDownloadResponse>(
        `/api/credit-order/download`,
        payload
      ),
    onSuccess: (response) => {
      toast({
        title: 'Pedido de download criado com sucesso!',
        status: 'success',
      });

      const signedUrl = response.data.url;

      downloadFileByUrl(signedUrl);
    },
    onError: () => {
      toast({
        title: 'Não foi possível fazer o download.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};
