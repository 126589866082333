import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { SunRiseIcon } from '../../assets/customIcons';
import BenefitCard from './components/BenefitCard';
import BenefitModal from './components/BenefitModal';
import {
  useDeleteBenefitPackage,
  useGetCompanyBenefitPackages,
  useGetSelectedCompanyBenefitCategories,
} from '../../api/benefit';
import Spinner from '../../components/Spinner';
import { BenefitPackage } from '../../types/benefit';
import CreateAndEditBenefitPackage from './components/CreateAndEditBenefitPackage';
import AlertDialog from '../../components/AlertDialog';
import BenefitEmptyState from './components/BenefitEmptyState';
import { LuPlusCircle } from 'react-icons/lu';

const BenefitsPage = () => {
  const [businessDays, setBusinessDays] = useState(20);
  const [benefitPackageToEdit, setBenefitPackageToEdit] =
    useState<BenefitPackage | null>(null);

  const createOrEditBenefitPackageDrawer = useDisclosure();
  const deleteBenefitPackageAlertDisclosure = useDisclosure();

  const { data: benefitPackagesData, isLoading } =
    useGetCompanyBenefitPackages();

  const benefitPackages = benefitPackagesData?.data || [];
  const companyPackagesNames = benefitPackages.map(
    (benefitPackage) => benefitPackage.name
  );

  const {
    data: companyBenefitCategories,
    isLoading: isLoadingCompanyBenefitCategories,
  } = useGetSelectedCompanyBenefitCategories({
    onlyActive: true,
  });

  const deleteBenefitPackageMutation = useDeleteBenefitPackage();

  function clearBenefitPackageToEdit() {
    setBenefitPackageToEdit(null);
  }

  function onBenefitPackageEdit(benefitPackage: BenefitPackage) {
    setBenefitPackageToEdit(benefitPackage);
    createOrEditBenefitPackageDrawer.onOpen();
  }

  function onBenefitPackageDelete(benefitPackage: BenefitPackage) {
    setBenefitPackageToEdit(benefitPackage);
    deleteBenefitPackageAlertDisclosure.onOpen();
  }

  function onCloseDeleteBenefitPackageAlert() {
    clearBenefitPackageToEdit();
    deleteBenefitPackageAlertDisclosure.onClose();
  }

  function onCloseCreateOrEditBenefitPackageDrawer() {
    clearBenefitPackageToEdit();
    createOrEditBenefitPackageDrawer.onClose();
  }

  function onDeleteBenefitPackage() {
    deleteBenefitPackageMutation.mutate(
      {
        benefitPackageId: benefitPackageToEdit?.id || '',
      },
      {
        onSuccess: () => {
          clearBenefitPackageToEdit();
          deleteBenefitPackageAlertDisclosure.onClose();
        },
      }
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        mb="40px"
      >
        <Button
          width="fit-content"
          leftIcon={<LuPlusCircle size="20px" />}
          onClick={createOrEditBenefitPackageDrawer.onOpen}
          isLoading={
            createOrEditBenefitPackageDrawer.isOpen &&
            isLoadingCompanyBenefitCategories
          }
        >
          Criar um novo pacote
        </Button>
      </Flex>

      <Flex justifyContent="space-between" alignItems="end" gap={4}>
        <Box maxWidth="800px">
          <Text fontSize="20px" fontWeight={500} mb={6}>
            Seus pacotes de benefícios
          </Text>

          <Text fontSize="16px">
            Os pacotes foram feitos para{' '}
            <Text as="span" fontWeight={600} color="gray.black">
              facilitar a sua vida!
            </Text>{' '}
            São{' '}
            <Text as="span" fontWeight={600} color="gray.black">
              conjuntos de benefícios
            </Text>{' '}
            que a sua empresa pode usar para{' '}
            <Text as="span" fontWeight={600} color="gray.black">
              vários colaboradores.
            </Text>
          </Text>
        </Box>

        <Box>
          <Flex alignItems="flex-end">
            <Text fontSize="14px" mb={1} w="213px" textAlign="end" mr="10px">
              *Os valores são uma estimativa considerando {businessDays} dias
              úteis.
            </Text>

            <Box>
              <Text fontSize="14px" mb={1}>
                Dias úteis
              </Text>
              <NumberInput
                width="136px"
                backgroundColor="white"
                borderRadius="12px"
                onChange={(value) => setBusinessDays(Number(value))}
                value={businessDays}
                min={1}
                max={31}
              >
                <NumberInputField background="gray.50" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          </Flex>
        </Box>
      </Flex>

      {benefitPackages.length > 0 ? (
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          marginBottom={10}
          marginTop={8}
        >
          {benefitPackages.map((benefitPackage: BenefitPackage) => {
            return (
              <GridItem colSpan={1} key={benefitPackage.id}>
                <BenefitCard
                  key={benefitPackage.id}
                  benefitPackage={benefitPackage}
                  businessDays={businessDays}
                  onBenefitPackageEdit={onBenefitPackageEdit}
                  onBenefitPackageDelete={onBenefitPackageDelete}
                />
              </GridItem>
            );
          })}
        </Grid>
      ) : (
        <Box marginY={4} height="calc(100vh - 390px)">
          <BenefitEmptyState
            isOpen={createOrEditBenefitPackageDrawer.isOpen}
            loadingCompanyBenefitCategories={isLoadingCompanyBenefitCategories}
            onOpen={createOrEditBenefitPackageDrawer.onOpen}
          />
        </Box>
      )}

      <Flex>
        <BenefitModal>
          <Link
            textDecor="underline"
            fontWeight={600}
            display="flex"
            minHeight="fit-content"
            gap={2}
          >
            <SunRiseIcon />
            Saiba onde usar cada um dos benefícios e categorias de uso livre
          </Link>
        </BenefitModal>
      </Flex>
      {createOrEditBenefitPackageDrawer.isOpen && (
        <CreateAndEditBenefitPackage
          companyPackagesNames={companyPackagesNames}
          companyBenefitCategories={companyBenefitCategories}
          isOpen={createOrEditBenefitPackageDrawer.isOpen}
          onClose={onCloseCreateOrEditBenefitPackageDrawer}
          benefitPackageToEdit={benefitPackageToEdit}
        />
      )}

      <AlertDialog
        title={`Deletar ${benefitPackageToEdit?.name}`}
        isOpen={deleteBenefitPackageAlertDisclosure.isOpen}
        onClose={onCloseDeleteBenefitPackageAlert}
        cancelButtonLabel="Fechar"
        confirmButtonLabel="Deletar pacote"
        cancelButtonAction={onCloseDeleteBenefitPackageAlert}
        confirmButtonAction={onDeleteBenefitPackage}
        isConfirmButtonLoading={deleteBenefitPackageMutation.isPending}
      >
        <Text fontWeight="400" fontSize="16px">
          Deseja realmente deletar esse pacote?
        </Text>
        <Text
          fontWeight="400"
          fontSize="16px"
          color="feedback.alert"
          marginTop={2}
        >
          Todas as informações serão perdidas
        </Text>
      </AlertDialog>
    </Box>
  );
};
export default BenefitsPage;
