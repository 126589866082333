import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Skeleton,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { H2 } from '../../../../components/Typography';
import { FiDownload } from 'react-icons/fi';
import { format } from 'date-fns';
import {
  RechargePaymentMethod,
  RechargePollingResponse,
} from '../../../../types/recharge';
import InvoiceDownloadButton from '../InvoiceDownloadButton';
import { useRechargeFileDownload } from '../../../../api/recharge';

export const DownloadButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        leftIcon={<FiDownload />}
        paddingY={2}
        paddingX={4}
        background={'gray.100'}
        variant={'ghost'}
        fontSize={'16px'}
        fontWeight={600}
        _disabled={{
          color: 'gray.300',
          _hover: {
            background: 'gray.100',
          },
        }}
        {...props}
      />
    );
  }
);

type RechargeDocsProps = {
  recharge: RechargePollingResponse | undefined;
  isPluralRecharge: boolean;
  statusIsProcessed: boolean;
};

const RechargeDocs = ({
  recharge,
  isPluralRecharge,
  statusIsProcessed,
}: RechargeDocsProps) => {
  const paymentMethodDueDate = format(
    new Date(recharge?.rechargeInfo?.invoice?.dueDate || new Date()),
    'dd/MM/yyyy'
  );
  const isRechargePaidWithAccountBalance =
    recharge?.rechargeInfo?.paymentMethod ===
    RechargePaymentMethod.ACCOUNT_BALANCE;

  const rechargeFileDownload = useRechargeFileDownload();

  return (
    <Box p={8} bg="white" borderRadius="8px" width="100%">
      <H2>Documentos da recarga</H2>
      {!statusIsProcessed && (
        <Text mt={9}>
          Se quiser, você pode fazer{' '}
          <Text as="span" fontWeight={600}>
            download{' '}
          </Text>
          dos documentos da recarga.
          <Text marginTop={4} as="span" display="block">
            Eles também serão enviados{' '}
            <Text as="span" fontWeight={600}>
              para você e para o time financeiro por e-mail.
            </Text>
          </Text>
        </Text>
      )}
      <Flex flexDir={'column'} gap={4} marginTop={8}>
        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          {!isRechargePaidWithAccountBalance && !statusIsProcessed && (
            <DownloadButton
              isDisabled={!recharge?.rechargeId}
              maxWidth="fit-content"
              height="56px"
              onClick={() => {
                if (!recharge?.rechargeId) {
                  return;
                }
                rechargeFileDownload.mutate({
                  documentType: 'PAYMENT',
                  rechargeId: recharge?.rechargeId,
                });
              }}
            >
              {recharge?.rechargeInfo?.paymentMethod === 'BOLETO' && 'Boleto'}
              {recharge?.rechargeInfo?.paymentMethod === 'PIX' && 'Código QR'}
            </DownloadButton>
          )}
        </Skeleton>

        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          <DownloadButton
            height="56px"
            isDisabled={!recharge?.rechargeId}
            onClick={() => {
              if (!recharge?.rechargeId) {
                return;
              }
              rechargeFileDownload.mutate({
                documentType: 'REPORT',
                rechargeId: recharge.rechargeId,
              });
            }}
          >
            Relatório da recarga
          </DownloadButton>
        </Skeleton>

        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          <InvoiceDownloadButton
            isPluralRecharge={isPluralRecharge}
            rechargeId={recharge?.rechargeId}
            fetchInvoice={!!recharge?.rechargeInfo}
          />
        </Skeleton>
      </Flex>
    </Box>
  );
};

export default RechargeDocs;
