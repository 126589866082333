import { Flex, Box, useDisclosure } from '@chakra-ui/react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import RechargeTable from './components/RechargeTable';
import { Employee } from '../../types/employee';
import { useMemo, useState } from 'react';
import FeedbackModal from '../../components/FeedbackModal';
import { useGetSelectedCompanyBenefitCategories } from '../../api/benefit';
import Spinner from '../../components/Spinner';
import { PrepareRechargeData } from '../../types/recharge';

const NewRechargePage = () => {
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const rechargeCreditDates = location.state?.rechargeCreditDates as
    | PrepareRechargeData['creditDates']
    | undefined;

  const selectedEmployees = location.state?.selectedEmployees as
    | Employee[]
    | undefined;

  const rechargeCompetencyInDays = location.state?.rechargeCompetencyInDays as
    | number
    | undefined;

  const isSpreadsheetImportLocation = location.state?.isSpreadsheetImport as
    | boolean
    | undefined;

  const [param] = useSearchParams();

  const isSpreadsheetImport = param.get('importacao') === '1';

  const importSuccessModal = useDisclosure({
    defaultIsOpen: isSpreadsheetImport,
  });

  // check if the sum of all benefits from all employees
  const benefitsSum = useMemo(() => {
    if (!selectedEmployees || selectedEmployees.length === 0) {
      return 0;
    }
    const total = selectedEmployees.reduce((acc, employee) => {
      // dont calculate if is not recharge from spreadsheet import
      if (!isSpreadsheetImport) {
        return 0;
      }
      const employeeSum = employee.categories.reduce(
        (benefitAcc, benefit) => benefitAcc + benefit.value,
        0
      );
      return acc + employeeSum;
    }, 0);
    return total;
  }, [selectedEmployees, isSpreadsheetImport]);

  const {
    data: benefitCategories = [],
    isLoading: isLoadingBenefitCategories,
  } = useGetSelectedCompanyBenefitCategories({
    onlyActive: true,
  });

  if (!selectedEmployees || selectedEmployees?.length === 0) {
    return <Navigate to="/recargas" />;
  }

  if (isLoadingBenefitCategories) {
    return <Spinner />;
  }

  return (
    <>
      <Box
        backgroundColor={isFullScreen ? 'gray.100' : 'transparent'}
        zIndex={isFullScreen ? 999 : 'unset'}
        paddingX={isFullScreen ? 4 : 0}
        width={isFullScreen ? '100vw' : 'unset'}
        height={isFullScreen ? '100vh' : 'unset'}
        position={isFullScreen ? 'fixed' : 'unset'}
        top={0}
        left={0}
      >
        <Flex
          id="flex-new-recharge-page"
          direction="column"
          justifyContent="flex-start"
          gap={4}
        >
          <RechargeTable
            employees={selectedEmployees}
            rechargeCompetencyInDays={rechargeCompetencyInDays}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            isSpreadsheetImport={
              isSpreadsheetImportLocation || isSpreadsheetImport
            }
            rechargeCreditDates={rechargeCreditDates}
            benefitCategories={benefitCategories}
          />
        </Flex>
      </Box>

      {importSuccessModal.isOpen && (
        <FeedbackModal
          title="Planilha importada com sucesso!"
          description={
            benefitsSum === 0
              ? 'A coluna CPF foi preenchida, mas faltam alguns valores de benefícios. Preencha a seguir os valores nos campos indicados'
              : 'Você pode revisar e editar os valores informados antes de continuar'
          }
          isOpen={importSuccessModal.isOpen}
          onClose={importSuccessModal.onClose}
        />
      )}
    </>
  );
};

export default NewRechargePage;
