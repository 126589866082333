import { useGetCustomerConfigs } from '../../api/configs';

import CheckoutOrderInfos from './components/Checkout';

import Spinner from '../../components/Spinner';
import RechargeStepper from './components/RechargeStepper';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useGetAccountBalance } from '../../api/recharge';
import { PrepareRechargeData } from '../../types/recharge';

const CheckoutOrder = () => {
  const getCustomerConfigs = useGetCustomerConfigs();

  const { data: accountBalance, isLoading: isLoadingAccountBalance } =
    useGetAccountBalance();
  const companyAccountBalance =
    accountBalance?.data.accountBalanceAvailable || 0;

  const location = useLocation();

  const rechargeCreditDates = location.state?.rechargeCreditDates as
    | PrepareRechargeData['creditDates']
    | undefined;
  const isSpreadsheetImport = location.state?.isSpreadsheetImport as
    | boolean
    | undefined;

  return (
    <>
      <Box marginBottom={6}>
        <RechargeStepper isSpreadsheetImport={isSpreadsheetImport} />
      </Box>
      {getCustomerConfigs.isLoading || isLoadingAccountBalance ? (
        <Spinner />
      ) : (
        <CheckoutOrderInfos
          rechargeVisibilityConfig={
            getCustomerConfigs?.data?.[0]?.rechargeVisibilityConfig
          }
          isSpreadsheetImport={isSpreadsheetImport}
          companyAccountBalance={companyAccountBalance}
          rechargeCreditDates={rechargeCreditDates}
        />
      )}
    </>
  );
};

export default CheckoutOrder;
