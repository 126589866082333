import RechargeList from './components/RechargeList';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useQuery } from '@tanstack/react-query';
import { RechargeListInfo } from '../../types/recharge';

const RechargeListPage = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const {
    data: rechargeListData = [],
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['recharge-list', selectedCompany?.id],
    queryFn: () =>
      api.get<RechargeListInfo[]>(
        `/api/recharges?companyId=${selectedCompany?.id}`
      ),
    select: (response) => response.data,
  });

  return (
    <>
      <RechargeList
        rechargeListData={rechargeListData}
        refetchRecharges={refetch}
        isFetching={isFetching}
      />
    </>
  );
};

export default RechargeListPage;
