import { Link } from 'react-router-dom';
import { UserHeartIcon } from '../../../../assets/customIcons';
import { Button, Card, CardProps, Flex, Text } from '@chakra-ui/react';
import { LuPlusCircle, LuUpload } from 'react-icons/lu';

const EmployeeEmptyState = (props: CardProps) => {
  return (
    <Card
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      padding={8}
      {...props}
    >
      <UserHeartIcon boxSize="56px" color="primary.primary" />
      <Flex flexDirection="column" alignItems="center" gap="3">
        <Text
          fontSize="32px"
          fontWeight={700}
          fontFamily="Sora"
          lineHeight={'130%'}
          textColor={'colors.black'}
        >
          Já Raiô!
        </Text>
        <Text
          fontSize="32px"
          fontFamily="Inter"
          lineHeight={'130%'}
          textColor={'colors.black'}
        >
          Está na hora de cadastrar os seus colaboradores!
        </Text>
      </Flex>

      <Flex gap={4} justifyContent={'center'} width="100%">
        <Button
          as={Link}
          leftIcon={<LuUpload size="20px" />}
          variant="outline"
          width="320px"
          to="/colaboradores/importar/preparar-planilha"
        >
          Cadastrar vários colaboradores
        </Button>
        <Button
          as={Link}
          leftIcon={<LuPlusCircle size="20px" />}
          width="320px"
          to="/colaboradores/cadastrar-colaborador"
        >
          Cadastrar um novo colaborador
        </Button>
      </Flex>
    </Card>
  );
};

export default EmployeeEmptyState;
