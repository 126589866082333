import { PiBarcode } from 'react-icons/pi';
import CustomDownloadLink from '../CustomDownloadLink';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import {
  RechargeFiles,
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../../types/recharge';

interface RechargePaymentMethodsButtonsProps {
  recharge: RechargeListInfo;
  rechargeFiles: RechargeFiles | undefined;
  onClick: () => void;
  isInternalProcessing: boolean;
}

const RechargePaymentMethodsButtons = ({
  recharge,
  rechargeFiles,
  onClick,
  isInternalProcessing,
}: RechargePaymentMethodsButtonsProps) => {
  if (!rechargeFiles) {
    return null;
  }

  const { PENDING, INTERNAL_PROCESSING } = RechargeStatusEnum;

  if (![PENDING, INTERNAL_PROCESSING].includes(recharge.status)) {
    return null;
  }

  return (
    <>
      {rechargeFiles.paymentType === RechargePaymentMethod.BOLETO && (
        <CustomDownloadLink
          isPending={isInternalProcessing}
          toolTipText={
            isInternalProcessing ? 'Boleto em processamento' : 'Boleto'
          }
          onClick={() => {
            if (isInternalProcessing) {
              return;
            }
            onClick();
          }}
        >
          <PiBarcode size="20px" color="fillCurrent" />
        </CustomDownloadLink>
      )}
      {rechargeFiles.paymentType === RechargePaymentMethod.PIX && (
        <CustomDownloadLink
          isPending={isInternalProcessing}
          toolTipText={
            isInternalProcessing ? 'Pix em processamento' : 'Pix Copia e Cola'
          }
          onClick={() => {
            if (isInternalProcessing) {
              return;
            }
            onClick();
          }}
        >
          <PixIcon />
        </CustomDownloadLink>
      )}
    </>
  );
};

export default RechargePaymentMethodsButtons;
