import { EmployeeBenefitCategory } from '../employee';

export type PaymentMethod = {
  id: string;
  method: string;
};

export type RechargeResultById = {
  amount: number;
  dueDate: string;
  observation: string | null;
  paymentMethod: RechargePaymentMethod;
  rechargeId: string;
  rechargeVisibilityConfig: string;
  scheduleDate: string;
  status: RechargeStatusEnum;
  createdDate?: string;
};

export type NewRechargeResultResponse = {
  id: string;
  paymentMethod: RechargePaymentMethod;
  amount: number;
  pix?: {
    scheduleDate?: string;
  };
  invoice?: {
    scheduleDate?: string;
  };
  accountBalance?: {
    scheduleDate?: string;
    dueDate?: string;
    accountBalanceAvailable?: number;
  };
  rechargeVisibilityConfig: RechargeVisibilityEnum;
};

export type EditRechargeResultResponse = {
  rechargeId: string;
  paymentMethod: RechargePaymentMethod;
  amount: number;
  pix?: {
    copyPaste?: string;
    qrCode?: string;
    dueDate?: string;
    scheduleDate?: string;
  };
  invoice?: {
    digitableLine?: string;
    pdf?: string;
    dueDate?: string;
    scheduleDate?: string;
  };
  accountBalance?: {
    scheduleDate?: string;
    accountBalanceAvailable?: number;
  };
  rechargeVisibilityConfig: RechargeVisibilityEnum;
  observation: string;
};

export type RechargePollingResponse = {
  rechargeId: string;
  rechargeInfo: null | {
    amount: number;
    paymentMethod: RechargePaymentMethod;
    rechargeVisibilityConfig: RechargeVisibilityEnum;
    scheduleDate: string;
    pix?: {
      copyPaste?: string;
      qrCode?: string;
      dueDate?: string;
    };
    invoice?: {
      digitableLine?: string;
      pdf?: string;
      dueDate?: string;
    };
    accountBalance?: {
      dueDate?: string;
      accountBalanceAvailable?: number;
    };
  };
};

export type RechargeInfo = {
  rechargeId: string;
  paymentMethod: RechargePaymentMethod;
  amount: number;
  pix?: {
    copyPaste?: string;
    qrCode?: string;
    dueDate?: string;
    scheduleDate?: string;
  };
  invoice?: {
    digitableLine?: string;
    pdf?: string;
    dueDate?: string;
    scheduleDate?: string;
  };
  accountBalance?: {
    scheduleDate?: string;
    accountBalanceAvailable?: number;
  };
  rechargeVisibilityConfig: RechargeVisibilityEnum;
  observation: string;
};

export enum RechargePaymentMethod {
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
}

export enum RechargePaymentMethodTranslation {
  PIX = 'Pix',
  BOLETO = 'Boleto',
}

export enum DocumentTypeEnum {
  PAYMENT = 'PAYMENT',
  REPORT = 'REPORT',
  INVOICE = 'INVOICE',
}

export enum RechargeStatusEnum {
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  RUNNING = 'RUNNING',
  PAID = 'PAID',
  INVALID_BALANCE = 'INVALID_BALANCE',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  SETTLED = 'SETTLED',
  INTERNAL_PROCESSING = 'INTERNAL_PROCESSING',
}

export type RechargeFiles = {
  id: string;
  paymentType: RechargePaymentMethod;
  paymentCode: string | null;
  paymentFilePath: string | null;
  reportFilePath: string | null;
  invoiceFilePath: string | null;
  bucket: string;
  rechargeId: string;
};

export type RechargeListInfo = {
  id: string;
  createdAt: string;
  createdDate: string;
  scheduledAt: string;
  status: RechargeStatusEnum;
  amount: number;
  paymentType: RechargePaymentMethod;
  rechargeFiles: RechargeFiles;
  modified: number;
  dueDate: string;
};

export type Invoice = {
  rechargeId: string;
  invoiceFilePath: string | null;
};

export enum RechargeVisibilityEnum {
  CREATED = 'CREATED',
  PAID = 'PAID',
  NEVER = 'NEVER',
}

export enum DifferentCreditDatesEnum {
  NO = 'NO',
  YES = 'YES',
}

export type RechargeDates = {
  creditDate: string;
  currentDate: string;
  dueDate: string;
  paymentMethod: keyof typeof RechargePaymentMethod;
};

export enum RechargeCancelReason {
  OVERDUE_PAYMENT = 'OVERDUE_PAYMENT',
  DUE_DATE_CHANGE = 'DUE_DATE_CHANGE',
  WRONG_VALUES = 'WRONG_VALUES',
  WRONG_BENEFITS = 'WRONG_BENEFITS',
  WRONG_EMPLOYEES = 'WRONG_EMPLOYEES',
  OTHER = 'OTHER',
}

export type PrepareRechargeData = {
  creditDates: Array<{
    date: string;
    numberOfCollaborators: number;
    totalValue: number;
  }>;
  collaborators: Array<{
    id: string;
    cpf: string;
    name: string;
    packageName: string;
    categories: EmployeeBenefitCategory[];
  }>;
};
