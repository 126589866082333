import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { H2 } from '../../../../components/Typography';
import { FiDownload } from 'react-icons/fi';
import {
  CreateCreditOrderResponse,
  PaymentTypeEnum,
} from '../../../../types/credits';
import { useCreditOrderFileDownload } from '../../../../api/credits';

export const DownloadButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        leftIcon={<FiDownload />}
        paddingY={2}
        paddingX={4}
        background={'gray.100'}
        variant={'ghost'}
        fontSize={'16px'}
        fontWeight={600}
        _disabled={{
          color: 'gray.300',
          _hover: {
            background: 'gray.100',
          },
        }}
        {...props}
      />
    );
  }
);

type OrderDocsProps = {
  orderInfo: CreateCreditOrderResponse | undefined;
};

const OrderDocs = ({ orderInfo }: OrderDocsProps) => {
  const creditOrderFileDownload = useCreditOrderFileDownload();

  return (
    <Box
      padding={8}
      background="white"
      borderRadius="8px"
      width={{
        base: '100%',
        xl: '496px',
      }}
    >
      <H2>Documentos do pedido</H2>

      <Text marginTop={9}>
        Se quiser, você pode fazer{' '}
        <Text as="span" fontWeight={600}>
          download{' '}
        </Text>
        do meio de pagamento escolhido.
        <Text marginTop={4} as="span" display="block">
          Eles também serão enviados{' '}
          <Text as="span" fontWeight={600}>
            para você e para o time financeiro por e-mail.
          </Text>
        </Text>
      </Text>

      <Flex flexDir={'column'} gap={4} marginTop={8}>
        <DownloadButton
          isDisabled={!orderInfo?.orderId}
          maxWidth="fit-content"
          height="56px"
          onClick={() => {
            if (!orderInfo) {
              return;
            }
            creditOrderFileDownload.mutate({
              orderId: orderInfo.orderId,
            });
          }}
        >
          {orderInfo?.paymentType === PaymentTypeEnum.BILLET && 'Boleto'}
          {orderInfo?.paymentType === PaymentTypeEnum.PIX && 'Código QR'}
        </DownloadButton>
      </Flex>
    </Box>
  );
};

export default OrderDocs;
