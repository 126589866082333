import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';

import { z } from 'zod';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { useGetUsers, usePatchUser, usePostUser } from '../../../../api/users';
import Select from '../../../../components/Select';
import Title from '../../../../components/Title';
import { employeeNameRegex } from '../../../../utils/validations/regex';
import { useMemo } from 'react';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher o nome do colaborador';
const PERMISSION_REQUIRED_MESSAGE =
  'Você precisa definir uma permissão de acesso para o usuário.';

const userSchema = z.object({
  fullName: z
    .string({
      required_error: NAME_REQUIRED_MESSAGE,
    })
    .min(1, NAME_REQUIRED_MESSAGE)
    .refine(
      (value) => employeeNameRegex.test(value),
      'O nome do usuário só pode conter letras e precisa de pelo menos um sobrenome.'
    ),
  username: z
    .string()
    .email({ message: 'Parece que este e-mail está incorreto.' }),
  policies: z
    .string({
      required_error: PERMISSION_REQUIRED_MESSAGE,
    })
    .min(1, PERMISSION_REQUIRED_MESSAGE),
});

type FormValues = z.infer<typeof userSchema>;

type AddAndEditUserProps = {
  isEdit?: boolean;
  userId?: string;
  isOpen: boolean;
  onClose: () => void;
};

const USER_PERMISSIONS_OPTIONS = [
  {
    value: 'ADMINISTRADOR',
    label: 'Administrador',
  },
  { value: 'GERAL', label: 'Geral' },
  { value: 'FINANCEIRO', label: 'Financeiro' },
] as const;

const AddAndEditUser = ({
  isEdit,
  userId,
  isOpen,
  onClose,
}: AddAndEditUserProps) => {
  const { isAdmin } = useAuthContext();
  const { selectedCompany } = useUserCompaniesStore();
  const { data: users } = useGetUsers({
    customerId: selectedCompany?.customerId || '',
  });
  const currentUser = users?.data?.find((user) => user.id === userId);
  const usePostUserMutation = usePostUser();
  const usePatchUserMutation = usePatchUser({
    userId: userId || '',
  });
  function getUserPolicies() {
    const currentCustomerUser = currentUser?.customerUsers?.find(
      (customerUser) => customerUser.customerId === selectedCompany?.customerId
    );
    const policies = currentCustomerUser?.policies[0]?.name || '';

    return policies;
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      userSchema.superRefine((values, ctx) => {
        if (checkRepeatedEmail(values.username)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'O e-mail selecionado já está sendo utilizado por outro usuário',
            path: ['username'],
          });
        }
      })
    ),
    defaultValues: {
      fullName: currentUser?.fullName || '',
      username: currentUser?.username || '',
      policies: getUserPolicies(),
    },
  });

  function checkRepeatedEmail(email: string) {
    if (email === currentUser?.username) return false;
    return users?.data?.some((user) => user.username === email);
  }

  const onSubmit = (values: FormValues) => {
    if (!values) return;

    if (errors.username) {
      setFocus('username');
    }

    const userData = {
      user: {
        fullName: values.fullName,
        username: values.username,
        policyName: values.policies,
      },
    };

    const handleSuccess = () => {
      reset();
      onClose();
    };

    if (isEdit) {
      usePatchUserMutation.mutate(userData, { onSuccess: handleSuccess });
    } else {
      usePostUserMutation.mutate(userData, { onSuccess: handleSuccess });
    }
  };

  const policyOptions = useMemo(() => {
    if (isAdmin) {
      return USER_PERMISSIONS_OPTIONS;
    } else {
      return USER_PERMISSIONS_OPTIONS.filter(
        (permission) => permission.value !== 'ADMINISTRADOR'
      );
    }
  }, [isAdmin]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          reset();
          onClose();
        }}
        size="sm"
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Title>{isEdit ? 'Editar usuário' : 'Novo usuário'}</Title>

            {!isEdit && (
              <Text fontSize="16px" mt={8} fontWeight={400}>
                Ao criar um novo usuário ele receberá um e-mail com instruções
                de primeiro acesso ao Portal Raiô.
              </Text>
            )}
          </DrawerHeader>
          <DrawerBody marginRight={-5} paddingRight={-5} mt={2}>
            <Box paddingRight={10}>
              <FormControl isInvalid={!!errors.fullName} mb={4}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  placeholder="Nome do usuário"
                  bg="white"
                  {...register('fullName')}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="fullName" />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.username} mb={4}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <Input
                  placeholder="nome.usuario@email.com"
                  bg="white"
                  {...register('username')}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="username" />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.policies} mb={4}>
                <FormLabel fontSize="sm">Permissão</FormLabel>
                <Box>
                  <Controller
                    name="policies"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputId="policies"
                        options={policyOptions}
                        value={policyOptions.find(
                          (permission) => field.value === permission.value
                        )}
                        onChange={(event) => {
                          if (event) {
                            field.onChange(event.value as string);
                          }
                        }}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Box>
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="policies" />
                </FormErrorMessage>
              </FormControl>

              <Button
                mt={8}
                w="full"
                onClick={handleSubmit(onSubmit)}
                isLoading={
                  usePostUserMutation.isPending ||
                  usePatchUserMutation.isPending
                }
              >
                {isEdit ? 'Salvar alterações' : 'Criar novo usuário'}
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddAndEditUser;
