import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency.ts';
import { RechargeListInfo } from '../../../../types/recharge/index.tsx';
import { getStatusTag } from '../utils/index.tsx';
import DocumentsCell from '../DocumentsCell/index.tsx';

const columnHelper = createColumnHelper<RechargeListInfo>();

export const defaultColumns = [
  columnHelper.accessor('id', {
    cell: (info) => {
      const id = info.getValue() as string | undefined;
      if (id) {
        return id.slice(-7);
      }
    },
    header: 'Recarga',
    enableSorting: false,
  }),

  columnHelper.accessor('createdDate', {
    cell: (info) => {
      const createdDate = info.getValue() as string | undefined;
      if (!createdDate) {
        return;
      }
      return format(new Date(createdDate), 'dd/MM/yy HH:mm');
    },
    header: 'Criada em',
    enableColumnFilter: true,
  }),
  columnHelper.accessor('scheduledAt', {
    cell: (info) => {
      const scheduledAt = info.getValue() as string | undefined;
      if (scheduledAt) {
        return format(new Date(scheduledAt), 'dd/MM/yy');
      }
      return 'Pendente';
    },
    header: 'Data do crédito',
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      return getStatusTag(info.row.original);
    },
    header: 'Status da recarga',
  }),
  columnHelper.accessor('amount', {
    // TODO: backend should return value in cents
    cell: (info) => formatCurrencyInCents(info.getValue() * 100),
    header: 'Valor total',
  }),
  columnHelper.accessor('dueDate', {
    cell: (info) => format(new Date(info.getValue()), 'dd/MM/yy'),
    header: 'Data de vencimento',
  }),
  columnHelper.accessor('rechargeFiles', {
    header: 'Documentos',
    enableSorting: false,
    cell: (info) => <DocumentsCell info={info} />,
  }),
];
