import { Flex, Icon, VStack, StackProps, FlexProps } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

type AlertMessageProps = StackProps & {
  messages: {
    icon: IconType;
    message: string | JSX.Element;
  }[];
  contentProps?: FlexProps;
  gap?: number;
};
const AlertMessage = ({
  messages,
  contentProps,
  gap = 6,
  ...props
}: AlertMessageProps) => {
  return (
    <VStack
      align="start"
      padding={6}
      spacing={6}
      width="100%"
      backgroundColor="light.orange"
      borderRadius="8px"
      color="black"
      {...props}
    >
      {messages.map(({ icon: IconComponent, message }, index) => (
        <Flex alignItems="center" gap={gap} key={index}>
          <Icon as={IconComponent} boxSize="24px" color="primary.primary" />
          {message}
        </Flex>
      ))}
    </VStack>
  );
};

export default AlertMessage;
