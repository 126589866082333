import { createIcon } from '@chakra-ui/react';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 16 17',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
      <g clipPath="url(#a)" fill="currentColor">
        <path d="M12.666 6.116v-.95a4.666 4.666 0 1 0-9.333 0v.95a3.333 3.333 0 0 0-2 3.05v4A3.337 3.337 0 0 0 4.666 16.5h6.667a3.338 3.338 0 0 0 3.333-3.333v-4a3.333 3.333 0 0 0-2-3.051Zm-8-.95a3.333 3.333 0 0 1 6.667 0v.667H4.666v-.666Zm8.667 8a2 2 0 0 1-2 2H4.666a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h6.667a2 2 0 0 1 2 2v4Z" />
        <path d="M8 9.834a.667.667 0 0 0-.667.667v1.333a.667.667 0 1 0 1.333 0v-1.333A.667.667 0 0 0 8 9.834Z" />
      </g>
    </svg>
  ),
});
