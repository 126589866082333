import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import HelpMessage from '../../components/HelpMessage';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  usePatchCustomerConfigs,
  usePostCustomerConfigs,
} from '../../api/configs';
import { CustomerConfig } from '../../types/config';
import {
  DifferentCreditDatesEnum,
  RechargeVisibilityEnum,
} from '../../types/recharge';
import DiscardChangesModal from './DiscardChangesModal';
import ExitWithoutSaveModal from './ExitWithoutSaveModal';
import { useEffect } from 'react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { FiUpload, FiEdit3, FiAlertCircle } from 'react-icons/fi';
import { Semibold } from '../../components/Typography';
import AlertMessage from '../../components/AlertMessage';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';

const alertMessages = [
  {
    icon: FiUpload,
    message: (
      <>
        <Text>
          Somente será possível fazer este processo através de{' '}
          <Semibold>recargas via planilha</Semibold>
        </Text>
      </>
    ),
  },
  {
    icon: FiEdit3,
    message: (
      <>
        <Text>
          Você vai importar apenas uma planilha, mas será criado{' '}
          <Semibold>um pedido de recarga para cada data de crédito</Semibold>{' '}
          escolhida
        </Text>
      </>
    ),
  },
  {
    icon: FiAlertCircle,
    message: (
      <>
        <Text>
          Apesar do processo ser único, você deverá fazer o{' '}
          <Semibold>pagamento dos pedidos</Semibold> de recarga{' '}
          <Semibold>de forma separada</Semibold>
        </Text>
      </>
    ),
  },
  {
    icon: HiOutlineCurrencyDollar,
    message: (
      <>
        <Text>
          No menu de recargas serão{' '}
          <Semibold>exibidos todos os pedidos de recargas</Semibold> referentes
          às datas de créditos escolhidas
        </Text>
      </>
    ),
  },
];

const RechargeConfigsSchema = z.object({
  configs: z.object({
    rechargeVisibilityConfig: z.nativeEnum(RechargeVisibilityEnum).optional(),
    differentCreditDates: z.nativeEnum(DifferentCreditDatesEnum).optional(),
  }),
});

type FormValues = z.infer<typeof RechargeConfigsSchema>;

const RechargeConfigs = ({
  customerConfig,
  tabsIndex,
}: {
  customerConfig?: CustomerConfig;
  tabsIndex: number;
}) => {
  const patchCustomerConfigsMutation = usePatchCustomerConfigs();
  const postCustomerConfigsMutation = usePostCustomerConfigs();
  const { selectedCompany } = useUserCompaniesStore();
  const discardChangesModal = useDisclosure();
  const exitWithoutSavingModal = useDisclosure();
  const defaultValues = {
    configs: {
      rechargeVisibilityConfig: customerConfig?.rechargeVisibilityConfig,
      differentCreditDates: customerConfig?.differentCreditDates,
    },
  };

  const onSubmit = (data: FormValues) => {
    if (customerConfig?.id) {
      patchCustomerConfigsMutation.mutate({
        data: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
          differentCreditDates: data.configs.differentCreditDates,
        },
        id: customerConfig.id || '',
      });
      reset({
        configs: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
          differentCreditDates: data.configs.differentCreditDates,
        },
      });
    } else {
      postCustomerConfigsMutation.mutate({
        data: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
          customerId: selectedCompany?.customerId,
          differentCreditDates: data.configs.differentCreditDates,
        },
      });
      reset({
        configs: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
          differentCreditDates: data.configs.differentCreditDates,
        },
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(RechargeConfigsSchema),
    defaultValues: defaultValues,
  });

  const differentCreditDates = watch('configs.differentCreditDates');

  useEffect(() => {
    if (isDirty && tabsIndex !== 2 && exitWithoutSavingModal.isOpen === false) {
      exitWithoutSavingModal.onOpen();
    }

    // removed exitWithoutSavingModal because it causes the modal to open twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsIndex, isDirty]);

  return (
    <>
      <form>
        <FormControl name="configs.rechargeVisibilityConfig" as="fieldset">
          <Text
            fontSize={'20px'}
            fontWeight={'500'}
            lineHeight={'130%'}
            marginBottom={8}
          >
            Informações da próxima recarga
          </Text>
          <Flex gap={1} marginBottom={4}>
            <Text>
              Quando devemos disponibilizar aos colaboradores da sua empresa as
              informações da próxima recarga no Aplicativo Raiô?
            </Text>
            <HelpMessage label="O colaborador irá visualizar uma mensagem na página inicial com as informações da próxima recarga" />
          </Flex>
          <Controller
            control={control}
            name="configs.rechargeVisibilityConfig"
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack>
                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.CREATED}>
                      Desde o momento em que a recarga foi criada
                    </Radio>
                    <HelpMessage label="Nesta opção, é exibida a data de crédito prevista, mesmo enquanto o Boleto/Pix não foi pago" />
                  </Flex>

                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.PAID}>
                      Só quando o Boleto/Pix for pago
                    </Radio>
                    <HelpMessage label="Nesta opção, é exibida a data de crédito e o valor de cada benefício, a partir da confirmação do pagamento do Boleto/Pix" />
                  </Flex>

                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.NEVER}>
                      Não mostrar essa informação
                    </Radio>
                    <HelpMessage label="Nesta opção, não é exibida nenhuma informação sobre a próxima recarga" />
                  </Flex>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <Box marginTop={8}>
          <Text
            fontSize={'20px'}
            fontWeight={'500'}
            lineHeight={'130%'}
            marginBottom={8}
          >
            Data de créditos das recargas
          </Text>
          <Flex gap={1} marginBottom={4}>
            <Text>
              Sua empresa gostaria de pagar benefícios para colaboradores com
              datas de créditos diferentes?
            </Text>
            <HelpMessage
              label={
                <>
                  <Text>
                    Você deverá fazer uma recarga via planilha e definir as
                    datas de créditos de benefícios para cada colaborador.
                  </Text>
                  <Text marginTop={6}>
                    Assim, cada um receberá na data personalizada.
                  </Text>
                </>
              }
            />
          </Flex>
          <Controller
            control={control}
            name="configs.differentCreditDates"
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Flex gap={6}>
                  <Radio value={DifferentCreditDatesEnum.YES}>Sim</Radio>
                  <Radio value={DifferentCreditDatesEnum.NO}>Não</Radio>
                </Flex>
              </RadioGroup>
            )}
          />
          {differentCreditDates === DifferentCreditDatesEnum.YES && (
            <Box marginTop={8}>
              <AlertMessage messages={alertMessages} />
            </Box>
          )}
        </Box>

        <Drawer
          isOpen={isDirty || patchCustomerConfigsMutation.isPending}
          placement="bottom"
          onClose={() => {}}
          variant="clickThrough"
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
        >
          <DrawerContent padding="24px 32px">
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
              padding={0}
            >
              <Button
                variant="outline"
                width="fit-content"
                onClick={() => {
                  discardChangesModal.onOpen();
                }}
              >
                Cancelar
              </Button>
              <Button
                width="fit-content"
                onClick={() => {
                  const values = getValues();
                  handleSubmit(() => onSubmit(values))();
                }}
                isLoading={patchCustomerConfigsMutation.isPending}
              >
                Salvar alterações
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {discardChangesModal.isOpen && (
          <DiscardChangesModal
            isOpen={true}
            onClose={discardChangesModal.onClose}
            onDiscardChanges={() => {
              reset(defaultValues);
              discardChangesModal.onClose();
            }}
          />
        )}

        {exitWithoutSavingModal.isOpen && (
          <ExitWithoutSaveModal
            isOpen={exitWithoutSavingModal.isOpen}
            onClose={() => {
              const values = getValues();
              handleSubmit(() => onSubmit(values))();
              exitWithoutSavingModal.onClose();
            }}
            onDiscardChanges={() => {
              exitWithoutSavingModal.onClose();
              reset(defaultValues);
            }}
          />
        )}
      </form>
    </>
  );
};

export default RechargeConfigs;
