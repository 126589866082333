import { CellContext } from '@tanstack/react-table';
import {
  DocumentTypeEnum,
  RechargeFiles,
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../../types/recharge';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Text,
  Box,
  Img,
  InputGroup,
  InputRightElement,
  Input,
  Skeleton,
} from '@chakra-ui/react';
import CustomDownloadLink from '../CustomDownloadLink';
import { FiFileText } from 'react-icons/fi';
import { DownloadButton } from '../../../NewRechargePage/components/RechargeDocs';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { Semibold } from '../../../../components/Typography';
import { formatCurrency } from '../../../../utils/formatters/formatCurrency';
import { RechargeReportIcon } from '../../../../assets/customIcons/RechargeReportIcon';
import InvoiceDownloadButton from '../InvoiceDownloadButton';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import CopyButton from '../../../../components/CopyButton';
import RechargePaymentMethodsButtons from '../RechargePaymentMethodsButtons';
import {
  useGetRechargeFileSignedURL,
  useRechargeFileDownload,
} from '../../../../api/recharge';
import { downloadFileByUrl } from '../../../../utils/downloadFileByUrl';

type DocumentsCellProps = {
  info: CellContext<RechargeListInfo, RechargeFiles>;
};

const DocumentsCell = ({ info }: DocumentsCellProps) => {
  const rechargeFiles = info.getValue() as
    | RechargeListInfo['rechargeFiles']
    | undefined;

  const recharge = info.row.original;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const rechargeFileSignedURL = useGetRechargeFileSignedURL();
  const rechargeFileDownload = useRechargeFileDownload();

  if (!rechargeFiles) {
    return null;
  }

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  const isInternalProcessing =
    recharge.status === RechargeStatusEnum.INTERNAL_PROCESSING;

  return (
    <>
      <Flex gap={2} minHeight="32px">
        <RechargePaymentMethodsButtons
          recharge={recharge}
          rechargeFiles={rechargeFiles}
          isInternalProcessing={isInternalProcessing}
          onClick={() => {
            if (rechargeFiles.paymentType === RechargePaymentMethod.PIX) {
              rechargeFileSignedURL.mutate({
                documentType: DocumentTypeEnum.PAYMENT,
                rechargeId: rechargeFiles.rechargeId,
              });
              onOpen();
            } else {
              rechargeFileDownload.mutate({
                documentType: DocumentTypeEnum.PAYMENT,
                rechargeId: rechargeFiles.rechargeId,
              });
            }
          }}
        />

        {!!(rechargeFiles.reportFilePath || isInternalProcessing) && (
          <CustomDownloadLink
            toolTipText={
              isInternalProcessing
                ? 'Relatório em processamento'
                : 'Relatório da recarga'
            }
            isPending={isInternalProcessing}
            onClick={() => {
              if (isInternalProcessing) {
                return;
              }
              rechargeFileDownload.mutate({
                documentType: DocumentTypeEnum.REPORT,
                rechargeId: rechargeFiles.rechargeId,
              });
            }}
          >
            <RechargeReportIcon boxSize="16px" />
          </CustomDownloadLink>
        )}

        <InvoiceDownloadButton recharge={recharge} />
      </Flex>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontWeight={700} fontSize="18px">
              Informações do Pix Cobrança
            </ModalHeader>
            <ModalCloseButton top="7" />
            <ModalBody marginTop={4}>
              <Flex direction="column" gap={6}>
                <Text>
                  Escaneie a imagem do <Semibold>código QR</Semibold> abaixo com
                  o aplicativo do seu banco para realizar o pagamento da
                  recarga:
                </Text>

                <Skeleton isLoaded={!!rechargeFileSignedURL.data}>
                  <Flex alignItems="flex-end" gap={6}>
                    <Img
                      src={rechargeFileSignedURL.data?.data.url}
                      margin="-20px"
                      backgroundSize="cover"
                      boxSize="250px"
                    />
                    <DownloadButton
                      color="gray.800"
                      maxWidth="fit-content"
                      height="48px"
                      onClick={() => {
                        const url = rechargeFileSignedURL.data?.data.url;
                        if (url) {
                          downloadFileByUrl(url);
                        }
                      }}
                    >
                      Código QR
                    </DownloadButton>
                  </Flex>
                </Skeleton>

                <Text fontSize="16px" fontWeight={400}>
                  Se preferir, pague utilizando o código{' '}
                  <Text as="span" fontWeight={600}>
                    Pix Copia e Cola
                  </Text>
                  :
                </Text>

                <InputGroup>
                  <InputRightElement
                    w="94px"
                    h="54px"
                    top="0.7px"
                    bg="white"
                    right="10px"
                    cursor="pointer"
                  >
                    <CopyButton copyText={rechargeFiles?.paymentCode || ''} />
                  </InputRightElement>
                  <Input
                    defaultValue={rechargeFiles?.paymentCode || ''}
                    paddingRight="105px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  />
                </InputGroup>

                <Text fontSize="28px" fontWeight={600}>
                  {formatCurrency(recharge.amount)}
                </Text>

                <Box>
                  <Text fontWeight={600} marginBottom={2}>
                    Dados do beneficiário
                  </Text>
                  <Flex
                    backgroundColor="gray.100"
                    borderRadius="lg"
                    padding={4}
                    gap={1}
                    alignItems="center"
                  >
                    <Icon
                      as={FiFileText}
                      color="primary.primary"
                      marginRight={1}
                    />
                    <Text fontWeight={600}>{paymentBeneficiary?.name}</Text>
                    <Text>
                      ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default DocumentsCell;
