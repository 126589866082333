import { Address } from '../address';
import { BenefitPeriodicity } from '../benefit';
import { CardTypeEnum } from '../card';
import { UserStatus } from '../user';

export enum CardStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
  MISPLACED = 'MISPLACED',
  EMITTED = 'EMITTED',
  NOT_FOUND = 'NOT_FOUND',
}

export enum EmployeeInfoStatusEnum {
  ACTIVE = 'ACTIVE',
  PROCESSING = 'PROCESSING',
  DISABLED = 'DISABLED',
}

export enum TranslateEmployeeStatusEnum {
  ACTIVE = 'Ativo',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  WAITING_ONBOARDING = 'Ativo',
  PENDING = 'Não acessou o app',
  INACTIVE = 'Conta excluída',
  PROCESSING = 'Em processamento',
}

export enum EmployeeStatusEnum {
  ACTIVE = 'ACTIVE',
  WAITING_ONBOARDING = 'WAITING_ONBOARDING',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  PROCESSING = 'PROCESSING',
}

export enum UsageTypeEnum {
  RESTRICT = 'RESTRICT',
  FREE_USAGE = 'FREE_USAGE',
}

export type EmployeeBenefitCategory = {
  id: string;
  name: string;
  period: BenefitPeriodicity;
  value: number;
  edited?: boolean;
  usageType?: UsageTypeEnum;
  isActive?: boolean;
  customerCategoryId?: string;
  order: number;
};
export type EmployeeProcessing = {
  id: string;
  name: string;
  cpf: string;
  createdAt: string;
  userId: string;
  cardType: string;
  packageName: string | null;
  status: string;
};

export type Employee = {
  id: string;
  name: string;
  cpf: string;
  personId: string;
  cardId: string | null;
  status: EmployeeStatusEnum;
  cardStatus: CardStatusEnum;
  packageName: string;
  activationCode: string;
  creditDate?: string;
  categories: EmployeeBenefitCategory[];
  cardType: keyof typeof CardTypeEnum;
};

export type EmployeeInfo = {
  collaboratorId: string;
  document: string;
  status: EmployeeInfoStatusEnum;
  isInMultiCompany: boolean;
};

export type EmployeeDetails = {
  id: string;
  personId?: string;
  activationCode?: string;
  address?: {
    id?: string;
    address?: string;
    city?: string;
    complement?: string | null;
    district?: string;
    number?: string | null;
    state?: string;
    zipCode?: string;
  };
  admissionDate?: string | null;
  cellphone?: string;
  companyTradeName: string;
  document: string;
  email?: string;
  fullName: string;
  identificationNumber?: string | null;
  packageId?: string;
  sendCommunicationDate?: string;
  socialName?: string | null;
  userStatus: UserStatus;
  status?: string;
};

export type PostEmployeeReturn = {
  name: string;
  cpf: string;
  package: string;
};

export type EmployeeAccount = {
  id: string;
  category: string;
  balance: number;
};

export type EmployeeBalanceResponse = {
  userId: string;
  totalBalance: number;
  accounts: EmployeeAccount[];
};
