import { Button } from '@chakra-ui/react';
import { EyeCloseIcon } from '../../assets/customIcons/EyeCloseIcon';
import { EyeOpenIcon } from '../../assets/customIcons/EyeOpenIcon';

type ToggleDisplayPasswordProps = {
  dataTestId?: string;
  shouldShowPassword: boolean;
  setShouldShowPassword: () => void;
};

const ToggleDisplayPassword = ({
  shouldShowPassword,
  setShouldShowPassword,
  dataTestId,
}: ToggleDisplayPasswordProps) => {
  return (
    <>
      <Button
        data-testid={dataTestId}
        width={{ base: '81px', sm: '105px' }}
        fontWeight={400}
        size="sm"
        padding={{ base: '4px 8px', sm: 'unset' }}
        fontSize={{ base: '12px', sm: '14px' }}
        colorScheme="gray"
        color="gray.800"
        background={{ base: 'gray.100', sm: 'gray.50' }}
        _hover={{
          background: 'gray.100',
        }}
        leftIcon={
          shouldShowPassword ? (
            <EyeCloseIcon boxSize={4} />
          ) : (
            <EyeOpenIcon boxSize={4} />
          )
        }
        onClick={setShouldShowPassword}
      >
        {shouldShowPassword ? 'Ocultar' : 'Mostrar'}
      </Button>
    </>
  );
};

export default ToggleDisplayPassword;
