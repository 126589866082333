import { Button, useDisclosure } from '@chakra-ui/react';
import SetBenefitPackageModal from '../SetBenefitPackageModal';
import { Employee, EmployeeProcessing } from '../../../../types/employee';
import { QueryKey } from '@tanstack/react-query';

const BenefitPackageColumn = ({
  employee,
  queryKeyToInvalidate,
}: {
  employee: Employee | EmployeeProcessing;
  queryKeyToInvalidate: QueryKey;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (employee.packageName) return employee.packageName;
  return (
    <>
      <Button variant="link" onClick={onOpen} padding={0}>
        Escolher pacote
      </Button>

      {isOpen && (
        <SetBenefitPackageModal
          isOpen={isOpen}
          onClose={onClose}
          employees={[employee] as Employee[] | EmployeeProcessing[]}
          queryKeyToInvalidate={queryKeyToInvalidate}
        />
      )}
    </>
  );
};

export default BenefitPackageColumn;
